import React, { Fragment } from 'react';
import {
  Text,
  Box,
  Divider,
  Stack,
  Button,
  IconButton,
  Flex,
  CloseButton,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { editStructure, setSelectedStructures } from '../../features/structures/structuresSlice';
import DeleteIcon from '../FigmaExport/DeleteIcon';


export const ConnectPropertyStructuresPanel = ({ isOpen, propertyId, originalPropertyStructures, ...props }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { selectedStructures } = useSelector(state => state.structures);

  const removeSelectedStructure = (index) => {
    dispatch(setSelectedStructures(selectedStructures.filter((_, i) => i !== index)));
  };

  const clearSelectedStructures = () => {
    dispatch(setSelectedStructures([]));
  };

  const onClose = () => {
    dispatch(setSelectedStructures(null));
    props.onClose();
  };

  const onConnect = async () => {
    try {
      for (const structure of selectedStructures) {
        if (structure.propertyId !== propertyId) {
          const structurePatch = [
            {
              op: 'replace',
              path: '/propertyId',
              value: propertyId
            }
          ];
          const result = await dispatch(editStructure({ structureId: structure.id, structurePatch }));
          if (!editStructure.fulfilled.match(result)) {
            throw new Error(`Connect Structure ${structure.id} to Property ${propertyId} Failed.`);
          }
        }
      }

      const ids = selectedStructures.map(s => s.id);
      for (const structure of originalPropertyStructures) {
        if (!ids.includes(structure.id)) {
          const structurePatch = [
            {
              op: 'replace',
              path: '/propertyId',
              value: null
            }
          ];
          const result = await dispatch(editStructure({ structureId: structure.id, structurePatch }));
          if (!editStructure.fulfilled.match(result)) {
            throw new Error(`Disconnect Structure ${structure.id} Failed.`);
          }
        }
      }

      toast({
        title: 'Success',
        position: 'top',
        description: 'Successfully connected property to selected structures.',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
    } catch (e) {
      console.log(e);
      toast({
        title: 'Error',
        position: 'top',
        description: 'Failed to connect property to selected structures. Please try again.',
        status: 'error',
        duration: 2500,
        isClosable: true
      });
    } finally {
      onClose();
    };
  };

  return isOpen && (
    <Box w="100%">
      <Flex>
        <Heading>
          Connect Property Structures
        </Heading>
        <CloseButton ml="auto" onClick={onClose} />
      </Flex>
      <Box>
        <Stack spacing="1.5rem">
          {(!selectedStructures || !selectedStructures.length) && (
            <Text>
              Click on objects on the map to select which structure you want to connect.
              To remove selection from a property, click on it again.
            </Text>
          )}
          {selectedStructures && (
            <Fragment>
              <Text>Selected Structures</Text>
              {selectedStructures.map((structure, index) => (
                <Flex key={index}>
                  <Text w="100%">{structure.name || "Structure"}</Text>
                  <IconButton bg="white" icon={<DeleteIcon />} onClick={() => removeSelectedStructure(index)} />
                </Flex>
              ))}
              <Divider />
              <Text>To remove selection from property, click on it again.</Text>
            </Fragment>
          )}
          <Divider />
          <Stack direction="row" justifyContent="space-between">
            <Button
              width="8rem"
              bg={'gray.400'}
              color={'white'}
              _hover={{
                bg: 'gray.500',
              }}
              onClick={clearSelectedStructures}
            >
              Clear
            </Button>
            <Button
              width="8rem"
              bg={'blue.400'}
              color={'white'}
              _hover={{
                bg: 'blue.500',
              }}
              onClick={onConnect}
            >
              Connect
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
