import React, { useState } from 'react';
import { Box, Stack, Text, Accordion, AccordionButton, AccordionItem, AccordionPanel, GridItem, Grid, Divider, IconButton, MenuButton, Menu, MenuList, MenuItem } from '@chakra-ui/react';
import MoreIcon from '../FigmaExport/MoreIcon';
import { AddressStack } from './AddressStack';


export const occupantFields = [
  {
    label: 'Occupant Label',
    name: 'name'
  },
  {
    label: 'Address',
    name: 'address'
  },
  {
    label: 'Occupancy Type',
    name: 'occupancyType'
  },
  {
    label: 'Sprinklered',
    name: 'sprinklered'
  },
  {
    label: 'Normal Population',
    name: 'normalPopulation'
  },
  {
    label: 'Owner Contact',
    name: 'ownerContact'
  },
  {
    label: 'Owner Phone',
    name: 'ownerPhone'
  },
  {
    label: 'Original Pre-Plan',
    name: 'originalPrePlan'
  },
  {
    label: 'Last Reviewed On',
    name: 'lastReviewedOn'
  },
  {
    label: 'Last Reviewed By',
    name: 'lastReviewedBy'
  },
  {
    label: 'Notes',
    name: 'notes'
  },
];


const OccupantInfoPanel = ({ occupant, handleEditOccupant, handleDeleteOccupant }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: '1.75rem',
    color: 'gray.700'
  };

  return occupant && (
    <Box>
      <Divider marginY={1}/>
      <Accordion defaultIndex={0} allowToggle>
        <AccordionItem border={0}>
          <h2>
            <AccordionButton paddingLeft={0} paddingY={0}>
              <Box as='h2' fontSize="md" fontWeight="500" flex='1' textAlign='left'>
                {occupant.name || "Occupant"}
              </Box>
              <Menu isOpen={menuIsOpen}>
                <MenuButton
                  ml="auto"
                  as={IconButton}
                  aria-label='Options'
                  icon={<MoreIcon />}
                  bg={'white'}
                  onClick={e => {
                    setMenuIsOpen(!menuIsOpen);
                    e.preventDefault();
                  }}
                />
                <MenuList>
                  <MenuItem onClick={() => {
                    setMenuIsOpen(false);
                    handleEditOccupant && handleEditOccupant(occupant);
                  }}>
                    Edit Occupant
                  </MenuItem>
                  <MenuItem onClick={() => {
                    setMenuIsOpen(false);
                    handleDeleteOccupant && handleDeleteOccupant(occupant);
                  }}>
                    Delete Occupant
                  </MenuItem>
                </MenuList>
              </Menu>
            </AccordionButton>
            
          </h2>
          <AccordionPanel padding={0}>
            <Stack spacing="">
              {occupantFields.map((field, index) => (!field.hideIfEmpty || occupant[field.name]) && (
                <Grid key={index} templateColumns='repeat(2, 1fr)'>
                  <GridItem><Text {...labelStyle}>{field.label}</Text></GridItem>
                  <GridItem>{field.name === "address" ? <AddressStack {...occupant.address} /> : <Text fontWeight="600" fontSize="sm">{occupant[field.name]}</Text>}</GridItem>
                </Grid>
              ))}
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};


export default OccupantInfoPanel;
