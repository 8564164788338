import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Text,
  HStack,
  Stack,
  Box,
  Button,
  Flex,
  Divider,
  Checkbox,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from '@chakra-ui/react';
import {
  setDashboardColumnsVisibility,
  getUIConfig
} from '../../features/user/userSlice';
import { allColumns } from './PrePlanTableColumns';


export const ColumnsPreferencesDrawer = ({ isOpen, onClose }) => {
  const [preferences, setPreferences] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const uiConfig = getUIConfig(user);

  useEffect(() => {
    setPreferences(uiConfig.dashboardColumnsVisibility);
  }, [uiConfig.dashboardColumnsVisibility]);

  const toggleValue = (columnId) => {
    setPreferences((prvPreferences) => ({ ...prvPreferences, [columnId]: !prvPreferences[columnId]}));
  };

  const setGroup = (colGroup, value) => {
    setPreferences((prvPreferences) => {
      const newPreferences = { ...prvPreferences };
      for (const column of colGroup.columns) {
        newPreferences[column.id] = value;
      }
      return newPreferences;
    });
  };

  const onApply = async () => {
    setLoading(true);
    await dispatch(setDashboardColumnsVisibility(preferences));
    setLoading(false);
    onClose();
  };

  return isOpen && (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={null}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          Columns
        </DrawerHeader>
        <DrawerBody>
          <Box>
            <Flex justifyContent="space-between" marginTop="-1rem" flexWrap="wrap">
              {allColumns.map((colGroup, i) => (
                <ColumnGroupCheckbox key={`column-group-${i}`} colGroup={colGroup} values={preferences} toggleValue={toggleValue} setGroup={setGroup} />
              ))}
              <Divider />
              <Stack direction="row" justifyContent="space-between">
                <Button
                  width="8rem"
                  bg={'gray.400'}
                  color={'white'}
                  _hover={{
                    bg: 'gray.500',
                  }}
                  isLoading={loading}
                  onClick={() => setPreferences({})}
                >
                  Clear
                </Button>
                <Button
                  width="8rem"
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  isLoading={loading}
                  onClick={onApply}
                >
                  Apply
                </Button>
              </Stack>
            </Flex>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const ColumnGroupCheckbox = ({ colGroup, values, toggleValue, setGroup }) => {
  // @todo to save my sanity. need to tie this to other property.
  // const restrictedColumns = ['id', 'lastReviewedBy'];

  const [allChecked, setAllChecked] = useState();
  const [isIndeterminate, setIsIndeterminate] = useState();

  useEffect(() => {
    const checkedItems = colGroup.columns.map(c => values[c.id]);
    setAllChecked(checkedItems.every(Boolean));
    setIsIndeterminate(checkedItems.some(Boolean) && !checkedItems.every(Boolean));
  }, [values, colGroup]);

  return (
    <Box marginTop="1rem">
      <Checkbox
        isIndeterminate={isIndeterminate}
        onChange={(e) => setGroup(colGroup, e.target.checked)}
        isChecked={allChecked}
        size="sm"
      >
        {colGroup.Header}
      </Checkbox>
      <HStack>
        {colGroup.columns.map((column, i) => (
          <Checkbox key={`cb-${i}`} onChange={(e) => toggleValue(column.id)} size="sm" isChecked={values[column.id]}>
            <Text fontSize="sm">{column.Header}</Text>
          </Checkbox>
        ))}
      </HStack>
    </Box>
  );
};
