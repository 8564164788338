import React, {useState} from 'react';
import {Box, Text, Divider, Heading, useColorModeValue, Flex, Stack, HStack, useToast} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import Modal from '../common/ModalComponent';
import { LinkButton, OutlineButton, PrimaryButton, SubmitButton, SubmitButtonDanger } from '../form/Button';
import { AddressStack } from '../common/AddressStack';
import { FlexWithBackgroundTheme } from '../../containers/ThemeContainer';
import { FaRegEdit } from '@react-icons/all-files/fa/FaRegEdit';
import { FaRegTrashAlt } from '@react-icons/all-files/fa/FaRegTrashAlt';
import { FaRedoAlt } from '@react-icons/all-files/fa/FaRedoAlt';
import { FaUserPlus } from '@react-icons/all-files/fa/FaUserPlus';
import { DataTable } from '../dashboard/DataTable';
import { FormProvider, useForm } from 'react-hook-form';
import { CreateUser, EditUser } from '../../models/User';
import { zodResolver } from '@hookform/resolvers/zod';
import FormComponent from '../../components/form/FormComponent';
import * as customerAPI from '../../api/CustomerAPI';
import * as sessionAPI from '../../api/SessionAPI';
import { useDispatch } from 'react-redux';
import { usTimeZones } from '../../helpers/Date';
import { NotificationSettingsCard } from '../../components/config/NotificationSettings';
import { setCustomerUiConfig } from '../../actions/customer-actions';
import { AccountUnitsCard } from './AccountUnitsCard';
import { IntegrationsCard } from '../config/IntegrationsCard';
import AccountOidcCard from "./AccountOidcCard";

const AccountInfoComponent = (props) => {
  // const user = useSelector(state => state.user);
  const uiConfig = useSelector(state => state.customer && state.customer.uiConfig);
  const dispatch = useDispatch();

  const onSubmitNotificationSettings = async(config) => {
    const result = await customerAPI.saveConfig(config);
    if (result && result.data) {
      dispatch(setCustomerUiConfig(result.data));
    }
    return result;
  };

  return (
    <FlexWithBackgroundTheme height={'calc(100vh - 4.25rem)'} overflow="auto" w="100%" flexDirection="column" paddingBottom="1.5rem" paddingX={'1.5rem'}>
      <Heading as="h2" mt="1rem" size="lg">Account Overview</Heading>
      <Divider />
      <AccountInfoCard />
      <AccountUsersCard />
      <AccountUnitsCard/>
      <NotificationSettingsCard config={uiConfig} onSubmit={onSubmitNotificationSettings} />
      <AccountOidcCard />
      <IntegrationsCard />
    </FlexWithBackgroundTheme>
  );
};

const AccountInfoCard = () => {
  const customer = useSelector(state => state.customer);
  const [isEditing, setIsEditing] = useState(false);
  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: '1.25rem',
    color: 'gray.700'
  };
  return (
    <Box marginTop="2rem" maxWidth="32rem" bg={useColorModeValue('white', 'gray.900')} p="1rem" borderWidth="1px" borderRadius="0.5rem" boxShadow="md">
      <Flex align="center" justify="space-between">
        <Heading as="h4" size="md">Department</Heading>
        <LinkButton onClick={() => setIsEditing(!isEditing)}>{isEditing ? 'Cancel' : 'Edit'}</LinkButton>
      </Flex>
      <Divider my="0.5rem" />
      {isEditing ? (
        <AccountInfoForm onSuccess={() => setIsEditing(false)} />
      ) : (
      <Box>
        <Stack spacing="0.5rem">
          <Stack spacing="0">
            <Text {...labelStyle}>Name</Text>
            <Text fontWeight="600">{customer.name}</Text>
          </Stack>
          <Stack spacing="0">
            <Text {...labelStyle}>Address</Text>
            <AddressStack {...customer.address} />
          </Stack>
          <Stack spacing="0">
            <Text {...labelStyle}>Time zone</Text>
            <Text fontWeight="600">{customer.timeZone}</Text>
          </Stack>
          {customer.latitude && customer.longitude && (
            <Stack spacing="0">
              <Text {...labelStyle}>Geolocation</Text>
              <Text fontWeight="600">{customer.latitude} , {customer.longitude}</Text>
            </Stack>
          )}
          {customer.oidc && (
            <Stack spacing="0">
              <Text {...labelStyle}>Code</Text>
              <Text fontWeight="600">{customer.slug}</Text>
            </Stack>
          )}
        </Stack>
      </Box>
      )}
    </Box>
  );
};

const AccountInfoForm = (props) => {
  const customer = useSelector(state => state.customer);

  const methods = useForm();
  const toast = useToast();
  const dispatch = useDispatch();

  const onSubmit = async(values) => {
    const data = [];
    const opVal = (path, value) => data.push({
      op: 'replace',
      path: `/${path}`,
      value
    });

    values.name && opVal('name', values.name);
    opVal('address/address1', values.address1 ? values.address1 : '');
    opVal('address/address2', values.address2 ? values.address2 : '');
    values.city && opVal('address/city', values.city);
    values.state && opVal('address/state', values.state);
    values.zip && opVal('address/zip', values.zip);
    values.timeZone && opVal('timeZone', values.timeZone);

    return new Promise(resolve => customerAPI.editCustomer(
      data,
      () => {
        toast({
          title: 'Success',
          position: 'top',
          description: 'Account information updated.',
          status: 'success',
          duration: 2500,
          isClosable: true
        });
        resolve();
        props.onSuccess();
      },
      () => {
        toast({
          title: 'Error',
          position: 'top',
          description: 'Failed to update account information. Please try again.',
          status: 'error',
          duration: 500,
          isClosable: true
        });
        resolve();
      },
      dispatch
    ));
  };


  const formFields = [
    {
      id: 'name',
      name: 'name',
      type: 'text',
      label: 'Name',
    },
    {
      id: 'address1',
      name: 'address1',
      type: 'text',
      label: 'Address 1',
    },
    {
      id: 'address2',
      name: 'address2',
      type: 'text',
      label: 'Address 2',
    },
    {
      id: 'city',
      name: 'city',
      type: 'text',
      label: 'City',
    },
    {
      id: 'state',
      name: 'state',
      type: 'text',
      label: 'State',
    },
    {
      id: 'zip',
      name: 'zip',
      type: 'text',
      label: 'Zip',
    },
    {
      id: 'timeZone',
      name: 'timeZone',
      type: 'select',
      label: 'Time Zone',
      options: usTimeZones()
    },
  ];

  const customerFormData = {
    name: customer.name,
    address1: customer.address.address1,
    address2: customer.address.address2,
    city: customer.address.city,
    state: customer.address.state,
    zip: customer.address.zip,
    timeZone: customer.timeZone,
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormComponent formFields={formFields} data={customerFormData} />
        <SubmitButton isLoading={methods.formState.isSubmitting} mt={4} colorScheme="blue" type="submit">
          Save
        </SubmitButton>
      </form>
    </FormProvider>
  );
};


const AccountUsersCard = (props) => {
  const customer = useSelector(state => state.customer);
  const users = useSelector(state => state.customer.users);

  const [userModalOpen, setUserModalOpen] = useState(false);

  const userColumns = [
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      maxWidth: 40,
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      maxWidth: 40
    },
    {
      Header: 'Role',
      accessor: 'role',
      maxWidth: 40,
    },
    {
      accessor: 'actions',
      maxWidth: 100,
      Cell: ({ value, row }) => {
        return (
          // FaRegEdit, FaRegTrashAlt, FaRedoAlt
          <HStack justifyContent="end" width="100%">
            <EditUserModal user={row.original} />
            <ResetUserPassword user={row.original} />
            <DeleteUserModal user={row.original} />
          </HStack>
        );
      }
    }
  ];
  if (customer?.oidc) {
    userColumns.splice(3, 0, {
      Header: "External Username",
      accessor: "oidc",
      Cell: ({ value }) => {
        return !!value ? (value?.preferredUsername ?? "Yes") : "";
      },
    });
  }

  return (
    <Box marginTop="2rem" minWidth="32rem" bg={useColorModeValue('white', 'gray.900')} p="1rem" borderWidth="1px" borderRadius="0.5rem" boxShadow="md">
      <Flex px="1rem" align="center" justify="space-between">
        <Heading as="h4" size="md">Users</Heading>
        <PrimaryButton onClick={() => setUserModalOpen(true)} aria-label="Add User" leftIcon={<FaUserPlus />}>Add User</PrimaryButton>
      </Flex>
      <Divider my="0.5rem" />
        <DataTable globalSearch columns={userColumns} data={users} />
      <AddUserModal isOpen={userModalOpen} closeModal={() => setUserModalOpen(false)} />
    </Box>
  );
};

const DeleteUserModal = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();
  const customer = useSelector(state => state.customer);

  const deleteUser = () => {
    setIsLoading(true);
    return new Promise(resolve => customerAPI.deleteUser(
      props.user.id,
      () => {
        //success
        setModalOpen(false);
        resolve();
        toast({
          title: 'Success',
          position: 'top',
          description: 'User deleted successfully',
          status: 'success',
          duration: 2500,
          isClosable: true
        });
      },
      () => {
        resolve();
        toast({
          title: 'Error',
          position: 'top',
          description: 'There was an error deleting this user, please try again.',
          status: 'error',
          duration: 2500,
          isClosable: true
        });
      },
      dispatch,
      customer.links && customer.links.find(x => x.rel === 'users')
    )).then(() => setIsLoading(false));

  };

  return (<React.Fragment>
    <OutlineButton
      size="sm"
      aria-label="Delete User"
      color="red.400"
      leftIcon={<FaRegTrashAlt />}
      onClick={() => setModalOpen(true)}
    >Delete</OutlineButton>
    <Modal
      showModal={modalOpen}
      toggleModal={() => setModalOpen(false)}
      modal={{
        heading: 'Confirm Delete',
        body: (<Text>Are you sure you want to delete this user?</Text>),
        footer: <SubmitButtonDanger isLoading={isLoading} onClick={deleteUser}>Delete</SubmitButtonDanger>
      }}
    />
  </React.Fragment>);
};

const ResetUserPassword = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const resetUserPassword = async() => {
    setIsLoading(true);
    return new Promise(resolve => sessionAPI.forgotPasswordResetRequest(
      { email: props.user.email },
      () => {
        //success
        resolve();
        toast({
          title: 'Success',
          position: 'top',
          description: 'Password reset email sent',
          status: 'success',
          duration: 2500,
          isClosable: true
        });
      },
      () => {
        resolve();
        toast({
          title: 'Error',
          position: 'top',
          description: 'There was an error requesting password reset, please try again.',
          status: 'error',
          duration: 2500,
          isClosable: true
        });
      }
    )).then(() => setIsLoading(false));
  };

  return (
    <OutlineButton
      size="sm"
      aria-label="Reset Password"
      leftIcon={<FaRedoAlt />}
      onClick={() => resetUserPassword()}
      isLoading={isLoading}
    >Reset Password</OutlineButton>
  );
};

const EditUserModal = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const methods = useForm({
    resolver: zodResolver(EditUser)
  });
  const dispatch = useDispatch();
  const toast = useToast();
  const customer = useSelector(state => state.customer);

  const initialData = {
    firstName: props.user.firstName,
    lastName: props.user.lastName,
    role: props.user.role
  };

  const formFields = [
    {
      id: 'firstName',
      name: 'firstName',
      type: 'text',
      label: 'First Name',
    },
    {
      id: 'lastName',
      name: 'lastName',
      type: 'text',
      label: 'Last Name',
    },
    {
      id: 'role',
      name: 'role',
      type: 'select',
      label: 'Role',
      options: [
        {
          label: 'Planner',
          value: 'PLANNER'
        },
        {
          label: 'User',
          value: 'USER'
        },
        {
          label: 'Admin',
          value: 'ADMIN'
        }
      ]
    }
  ];

  const onSubmit = async(data) => {
    return new Promise(resolve => customerAPI.editCustomerUser(
      { ...data, id: props.user.id },
      () => {
        //success
        setModalOpen(false);
        resolve();
        toast({
          title: 'Success',
          position: 'top',
          description: 'User updated successfully',
          status: 'success',
          duration: 2500,
          isClosable: true
        });
      },
      () => {
        resolve();
        toast({
          title: 'Error',
          position: 'top',
          description: 'There was an error updating the user. Please try again.',
          status: 'error',
          duration: 2500,
          isClosable: true
        });
      },
      dispatch,
      customer.links && customer.links.find(x => x.rel === 'users')
    ));

  };

  return (<React.Fragment>
    <OutlineButton
      size="sm"
      aria-label="Edit user"
      color="gray.900"
      leftIcon={<FaRegEdit />}
      onClick={() => setModalOpen(true)}
    >Edit</OutlineButton>
    <Modal
      showModal={modalOpen}
      toggleModal={() => setModalOpen(false)}
      modal={{
        heading: 'Update User',
        body: (<FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormComponent formFields={formFields} data={initialData} />
            <SubmitButton mb="1rem" isLoading={methods.formState.isSubmitting} mt={4} colorScheme="blue" type="submit">
              Submit
            </SubmitButton>
          </form>
        </FormProvider>)
      }}
    />
  </React.Fragment>);
};


const AddUserModal = (props) => {
  const methods = useForm({
    resolver: zodResolver(CreateUser)
  });
  const dispatch = useDispatch();
  const toast = useToast();
  const customer = useSelector(state => state.customer);

  const initialData = {
    email: '',
    role: 'USER'
  };

  const formFields = [
    {
      id: 'email',
      name: 'email',
      type: 'text',
      label: 'Email',
    },
    {
      id: 'role',
      name: 'role',
      type: 'select',
      label: 'Role',
      options: [
        {
          label: 'Planner',
          value: 'PLANNER'
        },
        {
          label: 'User',
          value: 'USER'
        },
        {
          label: 'Admin',
          value: 'ADMIN'
        }
      ]
    }
  ];

  const onSubmit = async(data) => {
    return new Promise(resolve => customerAPI.addCustomerUserMain(
      data,
      () => {
        //success
        props.closeModal();
        resolve();
        toast({
          title: 'User Created',
          position: 'top',
          description: `An email has been sent to ${data.email} with instructions on how to set up their account.`,
          status: 'success',
          duration: 2500,
          isClosable: true
        });
      },
      () => {
        resolve();
        toast({
          title: 'Error',
          position: 'top',
          description: 'There was an error creating the user. Please try again.',
          status: 'error',
          duration: 2500,
          isClosable: true
        });
      },
      dispatch,
      customer.links && customer.links.find(x => x.rel === 'users')
    ));

  };

  return (<Modal
    showModal={props.isOpen}
    toggleModal={props.closeModal}
    modal={{
      heading: 'Add User',
      body: (<FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormComponent formFields={formFields} data={initialData} />
          <SubmitButton mb="1rem" isLoading={methods.formState.isSubmitting} mt={4} colorScheme="blue" type="submit">
            Submit
          </SubmitButton>
        </form>
      </FormProvider >)
    }}
  />
  );
};

export default AccountInfoComponent;
