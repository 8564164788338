import { useToast } from '@chakra-ui/react';
import { addStructure, editStructure, setSelectedStructureId } from '../features/structures/structuresSlice';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addProperty, editProperty } from '../features/properties/propertiesSlice';
import { setSelectedHydrants } from '../features/hydrants/hydrantsSlice';


export const PrePlanFormContainer = (props) => {
  const selectedHydrantIds = useSelector(state => state.hydrants.selectedHydrantIds);
  const methods = useForm();
  const toast = useToast();
  const dispatch = useDispatch();

  const formAction = props.action;

  const onSubmit = (data) => {
    // we need prePlanGeoOutline from mapcontainer state for now
    const property = {
      name: data.propertyName,
      // geoOutline: props.prePlanGeoOutline,
      address: {
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip
      },
      // storey: data.storey,
      // storeyBelow: data.storeyBelow,
      // lotNumber: data.lotNumber,
      // customerZone: data.customerZone,
      // isHighHazard: data.isHighHazard,
      // highHazardDescription: data.highHazardDescription,
      // riskAssessmentScore: data.riskAssessmentScore,
      // roofArea: data.roofArea,
      // requiredFlow: data.requiredFlow,
    };

    const structure = {
      name: data.structureName,
      geoOutline: props.prePlanGeoOutline,
      address: {
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip
      },
      hydrants: selectedHydrantIds,
      // occupancyType: data.occupancyType,
      // constructionType: data.constructionType,
      // roofType: data.roofType,
      // roofConstruction: data.roofConstruction,
      // roofMaterial: data.roofMaterial,
      // sprinklered: data.sprinklered,
      // standPipe: data.standPipe,
      // fireAlarm: data.fireAlarm,
      // normalPopulation: data.normalPopulation,
      // hoursOfOperation: data.hoursOfOperation,
      // ownerContact: data.ownerContact,
      // ownerPhone: data.ownerPhone,
      // notes: data.notes,
      // partnerNotes: data.partnerNotes,
      // commercialStructure: data.commercialStructure,
      // storey: data.storey,
      // storeyBelow: data.storeyBelow,
      // lotNumber: data.lotNumber,
      // customerZone: data.customerZone,
      // isHighHazard: data.isHighHazard,
      // highHazardDescription: data.highHazardDescription,
      // riskAssessmentScore: data.riskAssessmentScore,
      // roofArea: data.roofArea,
      // requiredFlow: data.requiredFlow,
    };

    if (formAction === 'EDIT_STRUCTURE' || formAction === 'REPLAN_LOCATION') {
      return handleLocationUpdate(property, structure);
    } else if (formAction === 'ADD_LOCATION') {
      return handleAddLocation(property, structure);
    }
      return Promise.resolve();
  };

  const handleAddLocation = async(property, structure) => {
    const propertyResult = await dispatch(addProperty(property));

    if (addProperty.fulfilled.match(propertyResult)) {
      structure.propertyId = propertyResult.payload.id;

      const structureResult = await dispatch(addStructure(structure));
      if (addStructure.fulfilled.match(structureResult)) {
        dispatch(setSelectedStructureId(structureResult.payload.id));
        dispatch(setSelectedHydrants(structureResult.payload.hydrants || []));
        props.handleAddLocationSuccess();
        toast({
          title: 'Success',
          position: 'top',
          description: 'Pre-plan successfully created.',
          status: 'success',
          duration: 2500,
          isClosable: true
        });  
      } else {
        toast({
          title: 'Failed to create structure',
          position: 'top',
          description: 'Please check structure fields and try again.',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }

    } else {
      toast({
        title: 'Failed to create property',
        position: 'top',
        description: 'Please check property fields and try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  const handleLocationUpdate = async(property, structure) => {
    let propertyId = props.propertyId;

    if (!propertyId) {
      const propertyAddResult = await dispatch(addProperty(property));
      if (addProperty.fulfilled.match(propertyAddResult)) {
        propertyId = propertyAddResult.payload.id;
      } else {
        toast({
          title: 'Failed to create property',
          position: 'top',
          description: 'Please check property fields and try again.',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    } else {
      const propertyTmp = [
        {
          op: 'replace',
          path: '/name',
          value: property.name
        },
        {
          op: 'replace',
          path: '/address/address1',
          value: property.address.address1
        },
        {
          op: 'replace',
          path: '/address/address2',
          value: property.address.address2
        },
        {
          op: 'replace',
          path: '/address/city',
          value: property.address.city
        },
        {
          op: 'replace',
          path: '/address/state',
          value: property.address.state
        },
        {
          op: 'replace',
          path: '/address/zip',
          value: property.address.zip
        },
      ];
      const propertyPatch = [];
      //Remove all such items whose value is null or undefined
      for (let ii = 0; ii < propertyTmp.length; ii++) {
        if (typeof (propertyTmp[ii].value) === 'undefined') {
          continue;
        }

        if (propertyTmp[ii].value === null) {
          continue;
        }
        propertyPatch.push(propertyTmp[ii]);
      }
      const propertyEditResult = await dispatch(editProperty({ propertyId, propertyPatch }));
      if (!editProperty.fulfilled.match(propertyEditResult)) {
        toast({
          title: 'Update failed',
          position: 'top',
          description: 'Please check proprety fields and try again.',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    }

    const structureTmp = [
      {
        op: 'replace',
        path: '/name',
        value: structure.name
      },
      {
        op: 'replace',
        path: '/propertyId',
        value: propertyId
      },
      {
        op: 'replace',
        path: '/address/address1',
        value: structure.address.address1
      },
      {
        op: 'replace',
        path: '/address/address2',
        value: structure.address.address2
      },
      {
        op: 'replace',
        path: '/address/city',
        value: structure.address.city
      },
      {
        op: 'replace',
        path: '/address/state',
        value: structure.address.state
      },
      {
        op: 'replace',
        path: '/address/zip',
        value: structure.address.zip
      },
      {
        op: 'replace',
        path: '/storey',
        value: structure.storey
      },
      {
        op: 'replace',
        path: '/storeyBelow',
        value: structure.storeyBelow
      },
      {
        op: 'replace',
        path: '/lotNumber',
        value: structure.lotNumber
      },
      {
        op: 'replace',
        path: '/customerZone',
        value: structure.customerZone
      },
      {
        op: 'replace',
        path: '/isHighHazard',
        value: structure.isHighHazard
      },
      {
        op: 'replace',
        path: '/highHazardDescription',
        value: structure.highHazardDescription
      },
      {
        op: 'replace',
        path: '/riskAssessmentScore',
        value: structure.riskAssessmentScore
      },
      {
        op: 'replace',
        path: '/roofArea',
        value: structure.roofArea
      },
      {
        op: 'replace',
        path: '/requiredFlow',
        value: structure.requiredFlow
      },
      {
        op: 'replace',
        path: '/hydrants',
        value: structure.hydrants
      },
      {
        op: 'replace',
        path: '/occupancyType',
        value: structure.occupancyType
      },
      {
        op: 'replace',
        path: '/constructionType',
        value: structure.constructionType
      },
      {
        op: 'replace',
        path: '/roofType',
        value: structure.roofType
      },
      {
        op: 'replace',
        path: '/roofConstruction',
        value: structure.roofConstruction
      },
      {
        op: 'replace',
        path: '/roofMaterial',
        value: structure.roofMaterial
      },
      {
        op: 'replace',
        path: '/sprinklered',
        value: structure.sprinklered
      },
      {
        op: 'replace',
        path: '/standPipe',
        value: structure.standPipe
      },
      {
        op: 'replace',
        path: '/fireAlarm',
        value: structure.fireAlarm
      },
      {
        op: 'replace',
        path: '/normalPopulation',
        value: structure.normalPopulation
      },
      {
        op: 'replace',
        path: '/hoursOfOperation',
        value: structure.hoursOfOperation
      },
      {
        op: 'replace',
        path: '/ownerContact',
        value: structure.ownerContact
      },
      {
        op: 'replace',
        path: '/ownerPhone',
        value: structure.ownerPhone
      },
      {
        op: 'replace',
        path: '/notes',
        value: structure.notes
      },
      {
        op: 'replace',
        path: '/commercialStructure',
        value: structure.commercialStructure
      }
    ];
    if (props.prePlanGeoOutline) {
      if (props.prePlanGeoOutline.length > 2) {
        structureTmp.push({
          op: 'replace',
          path: '/geoOutline',
          value: props.prePlanGeoOutline
        });
      }
    }
    if(props.prePlanLatLng) {
      structureTmp.push({
        op: 'replace',
        path: '/latLon',
        value: props.prePlanLatLng
      });
    }
    const structurePatch = [];
    //Remove all such items whose value is null or undefined
    for (let ii = 0; ii < structureTmp.length; ii++) {
      if (typeof (structureTmp[ii].value) === 'undefined') {
        continue;
      }

      if (structureTmp[ii].value === null) {
        continue;
      }
      structurePatch.push(structureTmp[ii]);
    }
    const structureEditResult = await dispatch(editStructure({ structureId: props.structureId, structurePatch }));
    if (editStructure.fulfilled.match(structureEditResult)) {
      dispatch(setSelectedStructureId(structureEditResult.payload.id));
      dispatch(setSelectedHydrants(structureEditResult.payload.hydrants || []));
      props.handleEditLocationSuccess();
      toast({
        title: 'Success',
        position: 'top',
        description: 'Pre-plan successfully updated.',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
    } else {
      toast({
        title: 'Update failed',
        position: 'top',
        description: 'Please check structure fields and try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {props.children}
      </form>
    </FormProvider>
  );
};
