import React, { useEffect, useState } from 'react';
import { Box, Flex, Grid, Stack, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectAllAssignments } from '../../features/assignments/assignmentsSlice';
import { formatDateFromISO } from '../../helpers/Date';
import { AssignmentBadge } from './AssignmentBadge';
import { AssignmentStatusPopover } from './AssignmentStatusPopover';
import { getNameOrEmail } from '../../helpers/User';
import { getLatestAssignment } from './AssignmentUtil';

export const AssignmentCard = (/*props*/) => {
  const { selectedStructureId } = useSelector(state => state.structures);
  const customer = useSelector(state => state.customer);
  const user = useSelector(state => state.user);
  const assignments = useSelector(selectAllAssignments);
  const [currentAssignment, setCurrentAssignment] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  useEffect(() => {
    const latestAssignment = getLatestAssignment(assignments, selectedStructureId);
    if (latestAssignment) {
      // Check if assignment is cleared
      if (!latestAssignment.assignedTo && !latestAssignment.assignmentStatus && !latestAssignment.assignmentDueOn) {
        // cleared - act like no assignment exists
        setCurrentAssignment(null);
        return;
      }

      const assignedBy = customer.users.find(u => u.id === latestAssignment.assignedBy);
      const assignedTo = customer.users.find(u => u.id === latestAssignment.assignedTo);

      setCurrentAssignment({
        assignedBy: getNameOrEmail(assignedBy),
        assignedTo: getNameOrEmail(assignedTo),
        assignmentStatus: latestAssignment.assignmentStatus,
        assignmentDueOn: latestAssignment.assignmentDueOn,
        rawAssignmentData: latestAssignment,
      });
    } else {
      setCurrentAssignment(null);
    }
  // }, [selectedStructureId, assignments]);
  }, [selectedStructureId, assignments, customer]);


  useEffect(() => {
    if (user.role === 'ADMIN' || user.role === 'PLANNER') {
      setCanEdit(true);
    // } else if (user.id === (currentAssignment && currentAssignment.rawAssignmentData.assignedTo)) {
    //   setCanEdit(true);
    } else {
      setCanEdit(false);
    }
  }, [user, currentAssignment]);

  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    color: 'gray.700',
    lineHeight: '1.5rem'
  };

  const boxStyle = {
    borderWidth: '1px',
    borderRadius: '0.5rem',
    padding: '0.75rem',
  };

  const planAvailStyle = {
    margin: '-0.8rem',
    marginBottom: '0.5rem',
    bg: 'blue.400',
    padding: '0.25rem 0.75rem',
    borderTopRadius: '0.5rem',
  };

  return (<React.Fragment>
    {(currentAssignment && currentAssignment.assignmentStatus !== 'COMPLETE') && (
      <AssignmentStatusPopover canEdit={canEdit} assignment={currentAssignment.rawAssignmentData}>

        <Box style={boxStyle} cursor={canEdit ? 'pointer' : 'default'} mt={4}>
          <Box {...planAvailStyle}>
            <Text color="white" fontSize="xs" textTransform="uppercase" fontWeight="600">
              Active Assignment
            </Text>
          </Box>
          <Grid templateColumns="7.5rem 1fr 7.5rem" gap={'1rem'} rowGap="0">
            <Stack spacing={0}>
              <Text {...labelStyle}>Assigned To</Text>
              <Text fontSize="sm" fontWeight="600">{currentAssignment.assignedTo || <em>Not assigned</em>}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text {...labelStyle}>Due By</Text>
              <Text fontSize="sm" fontWeight="600">{currentAssignment.assignmentDueOn ? formatDateFromISO(currentAssignment.assignmentDueOn) : <em>–</em>}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text {...labelStyle}>Status</Text>
              <Flex>
                <AssignmentBadge status={currentAssignment.assignmentStatus} />
              </Flex>
            </Stack>
          </Grid>
        </Box>
      </AssignmentStatusPopover>
    )}
  </React.Fragment>);
};
