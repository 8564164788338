import React from 'react';
import { Stack, AccordionItem, AccordionButton, Heading, AccordionIcon, AccordionPanel, Accordion, Divider, Flex, Text, IconButton, Image } from '@chakra-ui/react';
import IconLegend from './IconLegendComponent';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllHydrants, setHydrantIdToLocate } from '../../features/hydrants/hydrantsSlice';
import LocationIcon from '../FigmaExport/LocationIcon';
import * as util from '../../helpers/Util';


export const FlowData = (props) => {
  return (
    <Flex gap="12px" textAlign="center">
      <Stack bg="#2C62CB1A" borderWidth='1px' borderRadius='lg' w="100%" spacing="4px" p= "4px">
        <Text color="#707A86" fontSize="sm" fontWeight="400" textTransform="uppercase">Selected Hydrants</Text>
        <Text color="#222234" fontSize="lg" fontWeight="600">{props.hydrantsLength}</Text>
      </Stack>
      <Stack bg="#2C62CB1A" borderWidth='1px' borderRadius='lg' w="100%" spacing="4px" p= "4px">
        <Text color="#707A86" fontSize="sm" fontWeight="400" textTransform="uppercase">Flow Rate</Text>
        <Text color="#222234" fontSize="lg" fontWeight="600">{props.totalFlowRate ? props.totalFlowRate.toLocaleString() : '--'} gal/min</Text>
      </Stack>
    </Flex>
  );
};

export const HydrantsInfoPanel = ({ structure }) => {
  const { pinLegend } = useSelector(state => state.customer);
  const hydrantIds = structure && structure.hydrants;
  const hydrants = useSelector(selectAllHydrants).filter(hydrant => hydrantIds && hydrantIds.includes(hydrant.id));
  const dispatch = useDispatch();


  const getFlowDataProps = () => {
    let totalHoses = 0;
    let totalFlowRate = 0;
    for (let i = 0; i < hydrants.length; i++) {
      if (hydrants[i].hydrantSize) {
        totalHoses = totalHoses + parseInt(hydrants[i].hydrantSize);
      }
      const flow =
        hydrants[i].flow === null ||
          hydrants[i].flow === undefined
          ? 500
          : parseInt(hydrants[i].flow);
      totalFlowRate = totalFlowRate + flow;
    }
    return {
      hydrantsLength: hydrants.length,
      totalHoses: totalHoses,
      totalFlowRate: totalFlowRate
    };
  };

  return (
    <Accordion defaultIndex={0} allowToggle borderWidth='1px' borderRadius='lg' >
      <AccordionItem border={0}>
        <AccordionButton>
          <Heading textAlign="left" flex="1" as="h5" size="md" fontWeight="500" >Structure Hydrants</Heading>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <Divider />
          <Stack spacing="1rem">
            <FlowData  {...getFlowDataProps()} />
            <Stack gap="6px">
              {hydrants && hydrants.map((hydrant, index) => (
                <Flex key={index} gap="4px" textAlign="left">
                  <Image h="24px" key={`h-img-${index}`} src={util.getMarkerIcon(hydrant.isMine, hydrant.dryHydrant, hydrant.inService, hydrant.flowRange && hydrant.flowRange.pinColor ? hydrant.flowRange.pinColor : null, true)} />
                  <Text w="100%" margin="auto" fontSize="sm" fontWeight="400" textColor="#707A86">Hydrant {index + 1}</Text>
                  <Text w="100%" margin="auto" fontSize="md" fontWeight="500" textColor="#222234">{hydrant.flow ? `${hydrant.flow.toLocaleString()} GPM` : '--'}</Text>
                  <Text w="100%" margin="auto" fontSize="md" fontWeight="500" textColor={hydrant.inService ? "#42B054" : "red"}>{hydrant.inService ? "Active" : "Inactive"}</Text>
                  <IconButton h="24px" bg="white" icon={<LocationIcon />} onClick={() => dispatch(setHydrantIdToLocate(hydrant.id))}/>
                </Flex>
              ))}
            </Stack>
            {Object.keys(pinLegend).length !== 0 && (
              <IconLegend pinLegend={pinLegend} />
            )}
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
