import React, { useState, useEffect } from 'react';

import {
  Text,
  HStack,
  Heading,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Box,
  Tab,
  Divider,
  Flex,
  Spinner,
  Center,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Button
} from '@chakra-ui/react';
import { AssignmentCard } from '../assignments/AssignmentCard';
import { useSelector } from 'react-redux';
import { selectAllStructures, selectStructureById } from '../../features/structures/structuresSlice';
import { selectAllOccupants } from '../../features/occupants/occupantsSlice';
import { selectAllProperties } from '../../features/properties/propertiesSlice';
import PropertyInfoPanel from './PropertyInfoPanel';
import StructureSummaryPanel from './StructureSummaryPanel';
import OccupantInfoPanel from './OccupantInfoPanel';
import StructureInfoPanel from './StructureInfoPanel';
import PropertyForm from '../form/PropertyForm';
import StructureForm from '../form/StructureForm';
import { useFormContext } from 'react-hook-form';
import { OutlineButton, SubmitButton } from '../form/Button';
import AddIcon from '../FigmaExport/AddIcon';



const TAB_KEYS = ['property', 'structure', 'occupants', 'firstIn', 'command'];

export const PrePlanPanel = (props) => {
  const { selectedStructureId } = useSelector(v => v.structures);
  const selectedStructure = useSelector(state => selectStructureById(state, selectedStructureId));
  const allProperties = useSelector(selectAllProperties);
  const allStructures = useSelector(selectAllStructures);
  const allOccupants = useSelector(selectAllOccupants);
  const [property, setProperty] = useState(null);
  const [structures, setStructures] = useState([]);
  const [occupants, setOccupants] = useState([]);
  const methods = useFormContext();

  
  const tabChange = (index) => {
    props.handleTabSelect(TAB_KEYS[index]);
  };

  useEffect(() => {
    if (!selectedStructureId || !selectedStructure || !selectedStructure.propertyId || !allProperties) {
      setProperty(null);
      return;
    }
    const newProperty = allProperties.find(p => p.id === selectedStructure.propertyId);
    setProperty(newProperty);
  }, [selectedStructureId, selectedStructure, allProperties]);

  useEffect(() => {
    if (!selectedStructureId || !selectedStructure) {
      setStructures([]);
      return;
    }
    if (!property || !allStructures) {
      setStructures([selectedStructure]);
      return;
    }
    const newStructures = allStructures.filter(s => s.propertyId === property.id);
    setStructures(newStructures);
  }, [allStructures, property, selectedStructure, selectedStructureId]);

  useEffect(() => {
    if (!allOccupants || !selectedStructureId) {
      setOccupants([]);
      return;
    }
    const newOccupants = allOccupants.filter(o => o.structureId === selectedStructureId);
    setOccupants(newOccupants);
  }, [selectedStructureId, allOccupants]);


  return (
    <Box fontFamily="Inter">
      <Box p="0.5rem" borderWidth="1px" borderRadius="0.5rem" boxShadow="sm">
        <Tabs
          index={TAB_KEYS.indexOf(props.currentTab)}
          onChange={tabChange}
          variant="soft-rounded"
          colorScheme="blue"
        >
          <TabList flexWrap="wrap">
            <Tab fontSize="sm">Property</Tab>
            <Tab fontSize="sm">Structure</Tab>
            {!props.editMode && <Tab fontSize="sm">Occupants</Tab>}
            {!props.editMode && selectedStructure &&  <Tab fontSize="sm">First In</Tab>}
            {!props.editMode && selectedStructure &&  <Tab fontSize="sm">Command</Tab>}
          </TabList>
          <TabPanels>
            <TabPanel paddingX={0}>
              {props.loading ? <Center><Spinner /></Center> :
              !props.editMode ? (
                <Stack gap="12px">
                  <PropertyInfoPanel property={property} handleConnectPropertyStructures={props.handleConnectPropertyStructures} />
                  {structures.map((structure, index) => (
                    <StructureSummaryPanel
                      key={index}
                      structure={structure}
                      isSelectedStructure={structure.id === selectedStructureId}
                      handleContextMenuItemSelect={props.handleContextMenuItemSelect}
                      handleDisconnect={props.handleDisconnect}
                    />
                  ))}
                </Stack>
              ) : (
                <Stack gap="12px">
                  <PropertyForm propertyDataForm={props.propertyDataForm} />
                </Stack>
              )}
            </TabPanel>
            <TabPanel paddingX={0}>
              {props.loading ? <Center><Spinner /></Center>:
              !props.editMode ? (
                <Stack gap="12px">
                  {structures.map((structure, index) => (
                    <StructureInfoPanel key={index} structure={structure} handleAddOccupant={props.handleAddOccupant} />
                  ))}
                </Stack>
              ) : (
                <Stack gap="12px">
                  <StructureForm structureDataForm={props.structureDataForm} />
                </Stack>
              )}
            </TabPanel>
            {!props.editMode && selectedStructure && <TabPanel paddingX={0}>
              <Box width="100%" padding="12px" borderWidth='1px' borderRadius='lg' overflow='hidden'>
                <Accordion defaultIndex={0} allowToggle>
                  <AccordionItem border={0}>
                    <h2>
                      <AccordionButton paddingLeft={0} paddingY={0}>
                        <Flex fontSize="lg" fontWeight="600" flex='1' textAlign='left'>
                          <Text alignContent="center">{selectedStructure.name || "Selected Structure"}</Text>
                          <Button
                            leftIcon={<AddIcon fill="#2C62CB" />}
                            color="#2C62CB"
                            bg={'white'}
                            fontSize="md"
                            onClick={() => props.handleAddOccupant(selectedStructure, false)}
                          >
                            Add Occupant
                          </Button>
                        </Flex>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel padding={0}>
                      <Stack gap="12px">
                        {occupants.map((occupant, index) => (
                          <OccupantInfoPanel
                            key={index}
                            occupant={occupant}
                            handleEditOccupant={props.handleEditOccupant}
                            handleDeleteOccupant={props.handleDeleteOccupant}
                          />
                        ))}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            </TabPanel>
            }
            {!props.editMode && selectedStructure && <TabPanel paddingX={0}>
              <FIOInfo
                structure={selectedStructure}
              />
            </TabPanel>
            }
            {!props.editMode && selectedStructure && <TabPanel paddingX={0}>
              <CommandInfo />
            </TabPanel>
            }
          </TabPanels>
        </Tabs>
        {props.editMode && (
          <React.Fragment>
            <Divider />
            <Flex paddingTop="0.5rem" alignItems="center" justifyContent="space-between" width="100%">
              {!props.loading && <SubmitButton width="100%" mr="1rem" isLoading={methods.formState.isSubmitting}>Save</SubmitButton>}
              <OutlineButton width="100%" onClick={props.onCancelPrePlan}>Cancel</OutlineButton>
            </Flex>
          </React.Fragment>
        )}
      </Box>
      <AssignmentCard />
    </Box >
  );
};

const FIOInfo = ({
  structure,
  ...props }) => (
  <Stack spacing="2rem" padding="0.5rem">
    <HStack paddingX="0.5rem">
      <Heading as="h5" size="sm">
        Roof Type:
      </Heading>
      {
        structure && structure.roofType && <Text fontSize="md">
          {structure.roofType}
        </Text>
      }
      {
        structure && !structure.roofType && <Text fontSize="md">
          {'Not defined'}
        </Text>
      }
    </HStack>
  </Stack>
);

const CommandInfo = () => (
  <div>
    <div className="command-container section-container">
      <div className="section-content">
        {''}
      </div>
    </div>
  </div>
);
