import * as types from '../actions/types';

const getDefaultLinks = () => {
  const defaultLinks = [];

  const tryParseAndPush = (item) => {
    try {
      item && defaultLinks.push(JSON.parse(item));
    } catch (e) {
      // console.error(e); // Sometimes the value is "undefined" which is not JSON parsable
    }
  };

  tryParseAndPush(sessionStorage.hydrant);
  tryParseAndPush(sessionStorage.location);
  tryParseAndPush(sessionStorage.preplan);
  tryParseAndPush(sessionStorage.customeruserslink);
  tryParseAndPush(sessionStorage.customerPartnersLink);
  tryParseAndPush(sessionStorage.symbolLink);

  return defaultLinks;
};

const defaultState = {
  pinLegend: {},
  config: {},
  partnerLocations: [],
  locations: [],
  origLocations: [],
  currentLocation: null,
  users: [],
  customerList: [],
  customerRadiusList: [],
  partners: [],
  smsList: [],
  customerId: null,
  psapId: null,
  slug: sessionStorage.slug || null,
  locationChangeKounter: null,
  locationFilterApplied: null,
  locationKounter: 0,
  locationsFetched: null,
  links: getDefaultLinks()
};

export default function customerReducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_CUSTOMER_LOGOUT: {
      return Object.assign({}, state, defaultState);
    }
    case types.SET_CUSTOMER: {
      const uiConfig = {
        lastLat: Number(localStorage.getItem('lastLat')),
        lastLon: Number(localStorage.getItem('lastLon')),
        lastZoom: Number(localStorage.getItem('lastZoom')),
        lastMapType: localStorage.getItem('lastMapType')
      };
      const newCustomerState = {
        config: {
          lat: uiConfig && uiConfig.lastLat ? uiConfig.lastLat : 41.35, // Default Indianapolis lat
          lon: uiConfig && uiConfig.lastLon ? uiConfig.lastLon : -88.84, // Default Indianapolis lon
          zoom: uiConfig && uiConfig.lastZoom ? uiConfig.lastZoom : 14,
          mapType:
            uiConfig && uiConfig.lastMapType
              ? uiConfig.lastMapType
              : 'hybrid'
        },
        uiConfig: action.customer.uiConfig || {},
        apiKeys: action.customer.apiKeys || [],
        customerId: action.customer.id,
        slug: action.customer.slug,
        psapId: action.customer.psapId,
        pinLegend: action.customer.pinLegend,
        links: action.customer.links,
        name: action.customer.name,
        address: action.customer.address,
        address1: action.customer.address
          ? action.customer.address.address1
          : '',
        address2: action.customer.address
          ? action.customer.address.address2
          : '',
        city: action.customer.address ? action.customer.address.city : '',
        state: action.customer.address ? action.customer.address.state : '',
        zip: action.customer.address ? action.customer.address.zip : '',
        latitude:
          action.customer.address && action.customer.address.latLon
            ? action.customer.address.latLon.latitude
            : '',
        longitude:
          action.customer.address && action.customer.address.latLon
            ? action.customer.address.latLon.longitude
            : '',
        licence: action.customer.license,
        smsNumber: action.customer.smsNumber,
        smsFormat: action.customer.smsFormat,
        emailGateway: action.customer.emailGateway,
        emailFormat: action.customer.emailFormat,
        emailSignature: action.customer.emailSignature,
        emailSignatureLocation: action.customer.emailSignatureLocation,
        fromContains: action.customer.fromContains,
        toContains: action.customer.toContains,
        subjectContains: action.customer.subjectContains,
        bodyContains: action.customer.bodyContains,
        fromNotContains: action.customer.fromNotContains,
        toNotContains: action.customer.toNotContains,
        subjectNotContains: action.customer.subjectNotContains,
        bodyNotContains: action.customer.bodyNotContains,
        boundSWLat: action.customer.boundSWLat,
        boundSWLon: action.customer.boundSWLon,
        boundNELat: action.customer.boundNELat,
        boundNELon: action.customer.boundNELon,
        dataSharingConsent: action.customer.dataSharingConsent,
        dispatchSharingConsent: action.customer.dispatchSharingConsent,
        timeZone: action.customer.timeZone ? action.customer.timeZone : 'America/Chicago',
      };
      return Object.assign({}, state, newCustomerState);
    }
    case types.SET_CUSTOMER_LIST: {
      const customers = action.customers;
      return Object.assign({}, state, { customerList: customers });
    }
    case types.SET_CUSTOMER_RADIUS_LIST: {
      const customers = action.customers;
      return Object.assign({}, state, { customerRadiusList: customers });
    }
    case types.SET_CUSTOMER_PARTNERS_LIST: {
      const partnersRec = action.partners;
      const partnersArr = [];
      if (partnersRec) {
        for (let ii = 0; ii < partnersRec.length; ii++) {
          partnersArr.push(partnersRec[ii].partnerId);
        }
      }
      return Object.assign({}, state, { partners: partnersArr });
    }
    case types.SET_CUSTOMER_CONFIG: {
      const newConfig = Object.assign({}, state.config);
      if (action.config && action.config.lastLat) {
        newConfig.lat = action.config.lastLat;
        localStorage.setItem('lastLat', action.config.lastLat);
      }
      if (action.config && action.config.lastLon) {
        newConfig.lon = action.config.lastLon;
        localStorage.setItem('lastLon', action.config.lastLon);
      }
      if (action.config && action.config.lastZoom) {
        newConfig.zoom = action.config.lastZoom;
        localStorage.setItem('lastZoom', action.config.lastZoom);
      }
      if (action.config && action.config.lastMapType) {
        newConfig.mapType = action.config.lastMapType;
        localStorage.setItem('lastMapType', action.config.lastMapType);
      }
      if (action.config && (action.config.disableDblClick !== undefined)) {
        newConfig.disableDblClick = action.config.disableDblClick;
        localStorage.setItem('disableDblClick', action.config.disableDblClick);
      }
      return Object.assign({}, state, { config: newConfig });
    }

    case types.SET_CUSTOMER_UI_CONFIG: {
      return Object.assign({}, state, { uiConfig: action.uiConfig });
    }

    case types.SET_CUSTOMER_MAP_FILTER: {
      return Object.assign({}, state, { mapFilter: action.mapFilter });
    }

    case types.SET_CUSTOMER_USERS: {
      const users = action.users;
      return Object.assign({}, state, { users: users });
    }

    case types.SET_CUSTOMER_DEFAULT_STATE: {
      return Object.assign({}, state, {
        pinLegend: {},
        config: {},
        locations: [],
        origLocations: [],
        locationChangeKounter: null,
        locationFilterApplied: null,
        currentLocation: null
      });
    }

    case types.SET_CUSTOMER_SMS: {
      const uniqList = uniqBy(action.smsList, JSON.stringify);
      return Object.assign({}, state, { smsList: uniqList });
    }

    case types.SET_NEW_MESSAGE: {
      const newMsg = JSON.parse(action.newSMS);
      let smsList = state.smsList;
      let alreadyPresent = false;
      if (!state.dispatchSharingConsent && state.customerId) {
        if (action.customerId !== newMsg.customerID) {
          alreadyPresent = true;
        }
      } else {
        if (action.customerId !== newMsg.customerID) {
          newMsg.isOthers = true;
        }
      }
      for (let ii = 0; ii < smsList.length; ii++) {
        if (smsList[ii].sequence === newMsg.sequence) {
          alreadyPresent = true;
          break;
        }
      }
      if (!alreadyPresent) {
        smsList = [newMsg, ...state.smsList];
      }
      smsList.sort(function(a, b) { return b.sequence - a.sequence; });
      return Object.assign({}, state, { smsList: smsList });
    }

    case types.SET_BACKLOG_MESSAGE: {
      const backlogMsg = JSON.parse(action.newSMS);
      let backsmsList = state.smsList;
      let backalreadyPresent = false;
      if (!state.dispatchSharingConsent && state.customerId) {
        if (action.customerId !== backlogMsg.customerID) {
          backalreadyPresent = true;
        }
      } else {
        if (action.customerId !== backlogMsg.customerID) {
          backlogMsg.isOthers = true;
        }
      }
      for (let ii = 0; ii < backsmsList.length; ii++) {
        if (backsmsList[ii].sequence === backlogMsg.sequence) {
          backalreadyPresent = true;
          break;
        }
      }
      if (!backalreadyPresent) {
        backsmsList = [backlogMsg, ...state.smsList];
      }
      backsmsList.sort(function(a, b) { return b.sequence - a.sequence; });
      return Object.assign({}, state, { smsList: backsmsList });
    }
    default:
      return state;
  }
}

function uniqBy(a, key) {
  const seen = new Set();
  return a.filter(item => {
    const k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
}
