import React from 'react';
import {
  Box,
  Stack,
  Text,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  GridItem,
  Grid,
  Divider,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useToast
} from '@chakra-ui/react';
import { AddressStack } from './AddressStack';
import { useDispatch, useSelector } from 'react-redux';
import { userRoleSelector } from '../../features/user/userSlice';
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { FaTrash } from "@react-icons/all-files/fa/FaTrash";
import { NavLink as RouterLink } from 'react-router-dom';
import PDFIcon from '../FigmaExport/PDFIcon';
import DisconnectIcon from '../FigmaExport/DisconnectIcon';
import { editStructure } from '../../features/structures/structuresSlice';


const StructureSummaryPanel = ({ structure, isSelectedStructure, handleContextMenuItemSelect, handleDisconnect }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const userRole = useSelector(userRoleSelector);

  const onDisconnectConfirm = async() => {
    const structurePatch = [
      {
        op: 'replace',
        path: '/propertyId',
        value: null
      }
    ];
    const result = await dispatch(editStructure({ structureId: structure.id, structurePatch }));
    if (editStructure.fulfilled.match(result)) {
      toast({
        title: 'Success',
        position: 'top',
        description: 'Successfully disconnected structure from property.',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
    } else {
      toast({
        title: 'Error',
        position: 'top',
        description: 'Failed to disconnect structure from property. Please try again.',
        status: 'error',
        duration: 2500,
        isClosable: true
      });
    }
  };

  const onDisconnect = (e) => {
    handleDisconnect(async () => await onDisconnectConfirm());
    e.preventDefault();
  };

  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: '1.75rem',
    color: 'gray.700'
  };

  return (structure && ( 
    <Box width="100%" padding="12px" paddingBottom="6px" borderWidth='1px' borderRadius='lg' overflow='hidden'>
      <Accordion defaultIndex={0} allowToggle>
        <AccordionItem border={0}>
          <h2>
            <AccordionButton paddingLeft={0} paddingY={0}>
              <Box as='h2' fontSize="lg" fontWeight="600" flex='1' textAlign='left'>
                {structure.name || "Structure"}
                {structure.propertyId && <IconButton ml="4px" bg="white" icon={<DisconnectIcon />} onClick={onDisconnect} />}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding={0}>
            <Divider marginY={1}/>
            <Stack>
              {isSelectedStructure && <Text fontSize="md" fontWeight="500">Selected Structure</Text>}
              <Grid templateColumns='repeat(2, 1fr)'>
                <GridItem><Text {...labelStyle}>Structure Name:</Text></GridItem>
                <GridItem><Text fontWeight="600" fontSize="sm">{structure.name}</Text></GridItem>
              </Grid>
              <Grid templateColumns='repeat(2, 1fr)'>
                <GridItem><Text {...labelStyle}>Structure Address:</Text></GridItem>
                <GridItem><AddressStack {...structure.address} /></GridItem>
              </Grid>
              <Grid templateColumns='repeat(2, 1fr)'>
                <GridItem><Text {...labelStyle}># of Occupants:</Text></GridItem>
                <GridItem><Text fontWeight="600" fontSize="sm">{structure.occupants && structure.occupants.length}</Text></GridItem>
              </Grid>
              <Grid templateColumns='repeat(2, 1fr)'>
                <GridItem><Text {...labelStyle}>Floors Above:</Text></GridItem>
                <GridItem><Text fontWeight="600" fontSize="sm">{structure.floorsAbove}</Text></GridItem>
              </Grid>
              <Grid templateColumns='repeat(2, 1fr)'>
                <GridItem><Text {...labelStyle}>Floors Below:</Text></GridItem>
                <GridItem><Text fontWeight="600" fontSize="sm">{structure.floorsBelow}</Text></GridItem>
              </Grid>
              <Grid templateColumns='repeat(2, 1fr)'>
                <GridItem><Text {...labelStyle}>Square Feet:</Text></GridItem>
                <GridItem><Text fontWeight="600" fontSize="sm">{structure.squareFeet}</Text></GridItem>
              </Grid>
              <Grid templateColumns='repeat(2, 1fr)'>
                <GridItem><Text {...labelStyle}>Required Fire Flow:</Text></GridItem>
                <GridItem><Text fontWeight="600" fontSize="sm">{structure.requiredFireFlow}</Text></GridItem>
              </Grid>
            </Stack>
            <React.Fragment>
            <Divider />
            <Flex paddingTop="6px" alignItems="center" justifyContent="space-between" gap="12px" width="100%">
              {(userRole === "ADMIN" || userRole === "PLANNER") &&
                <Menu>
                  <MenuButton
                    fontSize="sm"
                    color={'#2C62CB'}
                    bg={'white'}
                    ml="auto"
                    as={Button}
                    rightIcon={<FaChevronDown />}
                  >
                    Update Pre-Plan
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      key={"menu-edit"}
                      icon={<FaEdit />}
                      onClick={() => handleContextMenuItemSelect(
                        {
                          value: "EDIT_STRUCTURE"
                        },
                        {
                          structure: structure
                        }
                      )}
                    >
                      Edit Structure
                    </MenuItem>
                    {/* <MenuItem
                      key={"menu-redraw"}
                      icon={<FaDrawPolygon />}
                      onClick={() => handleContextMenuItemSelect(
                        {
                          value: "REPLAN_LOCATION"
                        },
                        {
                          structure: structure
                        }
                      )}
                    >
                      Re-Plan Location
                    </MenuItem> */}
                    <MenuItem
                      key={"menu-delete"}
                      icon={<FaTrash />}
                      onClick={() => handleContextMenuItemSelect(
                        {
                          value: "DELETE_STRUCTURE"
                        },
                        {
                          structure: structure
                        }
                      )}
                    >
                      Delete Structure
                    </MenuItem>
                  </MenuList>
                </Menu>
              }
              <Button
                fontSize="sm"
                color={'#2C62CB'}
                as={RouterLink}
                end
                to={`/export/${structure.id}`}
                rightIcon={<PDFIcon />}
                bg={'white'}
              >
                Export to PDF
              </Button>
            </Flex>
          </React.Fragment>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  ));
};


export default StructureSummaryPanel;
