import React from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { FormControl, Text, FormErrorMessage, Checkbox, Button, Stack, Link, Divider, useToast, Box, Heading, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { InputWithLabel } from '../form/FormWrapper';
import { zodResolver } from '@hookform/resolvers/zod';
import { CustomerRegistration } from '../../models/CustomerRegistration';
import * as sessionAPI from '../../api/SessionAPI';
import FlowLogo from '../../../svg/FlowMSPImageTrendLogo';


const customerFields = [
    {
        id: 'customerName',
        name: 'customerName',
        type: 'text',
        label: 'Department Name',
        required: true
    },
    {
        id: 'address1',
        name: 'address1',
        type: 'text',
        label: 'Address 1',
        required: true
    },
    {
        id: 'address2',
        name: 'address2',
        type: 'text',
        label: 'Address 2',
        required: false
    },
    {
        id: 'city',
        name: 'city',
        type: 'text',
        label: 'City',
        required: true
    },
    {
        id: 'state',
        name: 'state',
        type: 'text',
        label: 'State',
        required: true
    },
    {
        id: 'zip',
        name: 'zip',
        type: 'text',
        label: 'Zip',
        required: true
    }
];
const userFields = [
    {
        id: 'firstName',
        name: 'firstName',
        type: 'text',
        label: 'First Name',
        required: true
    },
    {
        id: 'lastName',
        name: 'lastName',
        type: 'text',
        label: 'Last Name',
        required: true
    },
    {
        id: 'email',
        name: 'email',
        type: 'email',
        label: 'Email',
        required: true
    },
    {
        id: 'password',
        name: 'password',
        type: 'password',
        label: 'Password',
        required: true
    },
    {
        id: 'confirmPassword',
        name: 'confirmPassword',
        type: 'password',
        label: 'Confirm Password',
        required: true,
        // confirmPasswordCheck: true
    }
];

export const CreateDepartmentForm = (props) => {
    const toast = useToast();
    const navigate = useNavigate();
    const methods = useForm({
        resolver: zodResolver(CustomerRegistration)
    });

    const termsOfService = {
        id: 'termsAndConditions',
        name: 'termsAndConditions',
        type: 'checkbox',
        labelType: 'jsx',
        label: {
            htmlTagType: 'link',
            preLinkText: 'I agree to the FlowMSP ',
            postLinkText: '',
            linkText: 'Terms of Service',
            href: props.hexagonUser ? '/FlowMSP_Terms_and_Conditions_for_Hexagon_Users.pdf' : '/FlowMSP_Terms_and_Conditions_05-03-22.pdf'
        },
        required: true
    };

    const createAccount = (form) => {
        const formData = {
            customerName: form.customerName,
            address: {
                address1: form.address1,
                address2: form.address2,
                city: form.city,
                state: form.state,
                zip: form.zip
            },
            firstName: form.firstName,
            lastName: form.lastName,
            email: form.email,
            password: form.password
        };
        return new Promise(resolve => sessionAPI.submitCreateDepartment(formData,
            props.linkPart,
            () => {
                toast({
                    title: 'Success',
                    position: 'top',
                    description: 'Congratulations! Your account was successfully created. You may now login and begin using FlowMSP.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true
                });
                resolve();
                navigate('/login');
            },
            () => {
                resolve();
                toast({
                    title: 'Request failed',
                    position: 'top',
                    description: 'We were unable to create your account. Please try again.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true
                });
            }));
    };


    return (
        <Box
            rounded={'xl'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'xl'}
            minWidth="24rem"
        >
            <Stack spacing="1rem" mx={'auto'} maxW={'xl'} py={6} px={6}>
                <Box align="center"><FlowLogo /></Box>
                <Divider />
                <Heading as="h4" size="md">Create new FlowMSP account</Heading>
                <Box
                    bg={useColorModeValue('white', 'gray.700')}
                >
                    <FormProvider {...methods}>
                        <Box mb="1rem">
                            <Text fontSize="sm" fontWeight="bold">
                                Department already use FlowMSP?
                            </Text>
                            <Text fontSize="sm">
                                To sign up as a new user for a department's FlowMSP account, check your email for a registration link.
                            </Text>
                        </Box>
                        <form onSubmit={methods.handleSubmit(createAccount)}>
                            <Text fontSize="sm" fontWeight="bold">
                                New Organization / Department
                            </Text>
                            <Divider mb="1rem" />
                            <Stack marginBottom="2rem">
                                {customerFields.map((field, i) => <InputWithLabel key={i} size="sm" {...field} />)}
                            </Stack>

                            <Text fontSize="sm" fontWeight="bold">
                                Administrative User
                            </Text>
                            <Divider marginBottom="1rem" />
                            <Stack marginBottom="2rem">
                                {userFields.map((field, i) => <InputWithLabel key={i} size="sm" {...field} />)}
                            </Stack>
                            <Stack spacing={'1rem'}>
                                <Controller
                                    control={methods.control}
                                    name="termsAndConditions"
                                    render={({
                                        field,
                                        fieldState
                                    }) => (<FormControl isInvalid={fieldState.invalid}>
                                        <Checkbox
                                            onBlur={field.onBlur}
                                            onChange={field.onChange}
                                            checked={field.value}
                                        >
                                            <Text fontSize="sm">
                                                I agree to the FlowMSP <Link isExternal color={'blue.300'} href={termsOfService.label.href}>Terms of Service</Link>
                                            </Text>
                                        </Checkbox>
                                        {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
                                    </FormControl>
                                    )}
                                />
                                <Controller
                                    control={methods.control}
                                    name="confirmNewAccount"
                                    render={({
                                        field,
                                        fieldState
                                    }) => (<FormControl isInvalid={fieldState.invalid}>
                                        <Checkbox
                                            onBlur={field.onBlur}
                                            onChange={field.onChange}
                                            checked={field.value}
                                        >
                                            <Text fontSize="sm">
                                                I confirm that I am creating a new organization/department FlowMSP account, for which I will be the initial administrative user.
                                            </Text>
                                        </Checkbox>
                                        {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
                                    </FormControl>
                                    )}
                                />
                                <Button
                                    marginTop="1rem"
                                    bg={'blue.400'}
                                    color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}
                                    type="submit"
                                    isLoading={methods.formState.isSubmitting}
                                >
                                    Create New Organization / Department
                                </Button>
                            </Stack>
                        </form>
                    </FormProvider>
                </Box>
            </Stack>
        </Box>
    );
};

export default CreateDepartmentForm;
