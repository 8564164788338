import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import FormComponent from './FormComponent';
import { Divider, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import { OutlineButton, SubmitButton, SubmitButtonDanger } from './Button';
import { useDispatch } from 'react-redux';
import { addOccupant, deleteOccupant, editOccupant } from '../../features/occupants/occupantsSlice';


export const occupantFormFields = [
  {
    label: 'Occupant Label',
    id: 'name',
    name: 'name',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Address 1',
    id: 'address1',
    name: 'address1',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Address 2',
    id: 'address2',
    name: 'address2',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'City',
    id: 'city',
    name: 'city',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'State',
    id: 'state',
    name: 'state',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Zip',
    id: 'zip',
    name: 'zip',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Occupancy Type',
    id: 'occupancyType',
    name: 'occupancyType',
    type: 'select',
    options: [
      {
        label: 'Select Occupancy Type',
        value: ''
      },
      {
        label: 'Assembly',
        value: 'Assembly'
      },
      {
        label: 'Board & Care',
        value: 'Board & Care'
      },
      {
        label: 'Business / Mercantile',
        value: 'Business / Mercantile'
      },
      {
        label: 'Day-Care',
        value: 'Day-Care'
      },
      {
        label: 'Detention & Correctional',
        value: 'Detention & Correctional'
      },
      {
        label: 'Educational',
        value: 'Educational'
      },
      {
        label: 'High Hazard',
        value: 'High Hazard'
      },
      {
        label: 'Industrial',
        value: 'Industrial'
      },
      {
        label: 'Medical Care / Institutional',
        value: 'Medical Care / Institutional'
      },
      {
        label: 'Mixed Business / Residential',
        value: 'Mixed Business / Residential'
      },
      {
        label: 'Multi-Family',
        value: 'Multi-Family'
      },
      {
        label: 'Residential',
        value: 'Residential'
      },
      {
        label: 'Special Structures',
        value: 'Special Structures'
      },
      {
        label: 'Storage',
        value: 'Storage'
      }
    ],
    required: false
  },
  {
    label: 'Sprinklered',
    id: 'sprinklered',
    name: 'sprinklered',
    type: 'select',
    options: [
      {
        label: 'Select Sprinkler Type',
        value: ''
      },
      {
        label: 'Dry System',
        value: 'Dry System'
      },
      {
        label: 'Wet System',
        value: 'Wet System'
      },
      {
        label: 'Both',
        value: 'Both'
      },
      {
        label: 'None',
        value: 'None'
      }
    ],
    required: false
  },
  {
    label: 'Normal Population',
    id: 'normalPopulation',
    name: 'normalPopulation',
    type: 'select',
    options: [
      {
        label: 'Select Normal Population',
        value: ''
      },
      {
        label: 'Vacant',
        value: 'Vacant'
      },
      {
        label: '1 - 10',
        value: '1 - 10'
      },
      {
        label: '11 - 50',
        value: '11 - 50'
      },
      {
        label: '51 - 100',
        value: '51 - 100'
      },
      {
        label: '101 - 500',
        value: '101 - 500'
      },
      {
        label: '501 - 1000',
        value: '501 - 1000'
      }
    ],
    required: false
  },
  {
    label: 'Owner Contact',
    id: 'ownerContact',
    name: 'ownerContact',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Owner Phone',
    id: 'ownerPhone',
    name: 'ownerPhone',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Notes (Optional)',
    id: 'notes',
    name: 'notes',
    type: 'textarea',
    placeholder: ''
  },
];


export const OccupantForm = ({
  occupant,
  action,
  closeModal
}) => {
  const methods = useForm();
  const toast = useToast();
  const dispatch = useDispatch();


  const handleAddOccupant = async(occupant) => {

    const result = await dispatch(addOccupant(occupant));

    if (addOccupant.fulfilled.match(result)) {
      onSuccess('Success', 'Occupant added successfully');
    } else {
      onError('Failed to add occupant', 'Please check occupant fields and try again.');
    }
  };

  const handleEditOccupant = async(id, occupantPatch) => {
    const result = await dispatch(editOccupant({ occupantId: id, occupantPatch: occupantPatch }));

    if (editOccupant.fulfilled.match(result)) {
      onSuccess('Success', 'Occupant updated');
    } else {
      onError('Failed to update occupant', 'Please check occupant fields and try again.');
    }
  };

  const onSubmit = (data) => {
    if (action === 'ADD_OCCUPANT') {
      const occupantAdd = {
        structureId: occupant.structureId,
        address: {
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          state: data.state,
          zip: data.zip
        },
      };
      data.name && (occupantAdd.name = data.name);
      data.occupancyType && (occupantAdd.occupancyType = data.occupancyType);
      data.sprinklered && (occupantAdd.sprinklered = data.sprinklered);
      data.normalPopulation && (occupantAdd.normalPopulation = data.normalPopulation);
      data.ownerContact && (occupantAdd.ownerContact = data.ownerContact);
      data.ownerPhone && (occupantAdd.ownerPhone = data.ownerPhone);
      data.notes && (occupantAdd.notes = data.notes);

      return handleAddOccupant(occupantAdd);
    } else if (action === 'EDIT_OCCUPANT') {
      const occupantPatch = [];
      data.name && occupantPatch.push({
        op: 'replace',
        path: '/name',
        value: data.name
      });
      data.address1 && occupantPatch.push({
        op: 'replace',
        path: '/address/address1',
        value: data.address1
      });
      data.address2 && occupantPatch.push({
        op: 'replace',
        path: '/address/address2',
        value: data.address2
      });
      data.city && occupantPatch.push({
        op: 'replace',
        path: '/address/city',
        value: data.city
      });
      data.state && occupantPatch.push({
        op: 'replace',
        path: '/address/state',
        value: data.state
      });
      data.zip && occupantPatch.push({
        op: 'replace',
        path: '/address/zip',
        value: data.zip
      });
      data.occupancyType && occupantPatch.push({
        op: 'replace',
        path: '/occupancyType',
        value: data.occupancyType
      });
      data.sprinklered && occupantPatch.push({
        op: 'replace',
        path: '/sprinklered',
        value: data.sprinklered
      });
      data.normalPopulation && occupantPatch.push({
        op: 'replace',
        path: '/normalPopulation',
        value: data.normalPopulation
      });
      data.ownerContact && occupantPatch.push({
        op: 'replace',
        path: '/ownerContact',
        value: data.ownerContact
      });
      data.ownerPhone && occupantPatch.push({
        op: 'replace',
        path: '/ownerPhone',
        value: data.ownerPhone
      });
      data.notes && occupantPatch.push({
        op: 'replace',
        path: '/notes',
        value: data.notes
      });
      return handleEditOccupant(occupant.id, occupantPatch);
    } else if (action === 'DELETE_OCCUPANT') {
      return handleDeleteOccupant(occupant.id);
    }
    return Promise.resolve();
  };

  const handleDeleteOccupant = async(id) => {
    const response = await dispatch(deleteOccupant(id));
    if (deleteOccupant.fulfilled.match(response)) {
      onSuccess('Success', 'Occupant deleted');
    } else {
      onError('Cannot Delete Occupant');
    }
  };

  const onError = (...p) => onComplete('error', ...p);

  const onSuccess = (...p) => {
    closeModal();
    onComplete('success', ...p);
  };

  const onComplete = (type, title, body) => {
    toast({
      title: title,
      position: 'top',
      description: body,
      status: type,
      duration: 2500,
      isClosable: true
    });
  };

  const onCancel = () => {
    methods.reset();
    closeModal();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {(action === 'DELETE_OCCUPANT') ? (
          <DeleteForm onCancel={onCancel} />
        ) : (
          <EditForm occupant={occupant} onCancel={onCancel} />
        )}
      </form>
    </FormProvider>
  );
};

const DeleteForm = (props) => {
  const methods = useFormContext();

  return (
    <Stack spacing="1rem">
      <Text>Are you sure you want to delete this occupant?</Text>
      <Divider />
      <HStack justifyContent="end" spacing="1rem">
        <OutlineButton onClick={props.onCancel} width="8rem">No</OutlineButton>
        <SubmitButtonDanger isLoading={methods.formState.isSubmitting} width="8rem">Delete</SubmitButtonDanger>
      </HStack>
    </Stack>
  );
};

const EditForm = ({
  onCancel,
  occupant
}) => {
  const methods = useFormContext();

  return (
    <Stack spacing="1rem">
      <FormComponent formFields={occupantFormFields} data={occupant} />
      <Divider />
      <HStack justifyContent="end" spacing="1rem">
        <OutlineButton onClick={onCancel} width="8rem">Cancel</OutlineButton>
        <SubmitButton isLoading={methods.formState.isSubmitting} width="8rem">Save</SubmitButton>
      </HStack>
    </Stack>
  );

};

export default OccupantForm;
