import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import MapContainer from './containers/MapContainer';
import LocationContainer from './containers/LocationContainer';
import UploadDataContainer from './containers/UploadDataContainer';
// import ReportPanelContainer from './containers/ReportPanelContainer';
import DataSharingContainer from './containers/DataSharingContainer';
import { PreplansContainer } from './containers/dashboard/PrePlanTable';
import { ProfileContainer } from './containers/dashboard/ProfileContainer';
import AccountInfoComponent from './components/AccountInfo/AccountInfoComponent';
import { PrePlanExport } from './components/pdf/Preplan';
import {RequireAuth} from "./components/RequireAuth";
import App from "./annotator-demo/App";

export default (
    <Routes>

        <Route exact path="/login" element={
            <Navigate to="/" replace />
        } />
        <Route exact path="/passwordResetForm" element={
            <Navigate to="/" replace />
        } />
        <Route exact path="/signUpForm" element={
            <Navigate to="/" replace />
        } />
        <Route exact path="/createDepartment" element={
            <Navigate to="/" replace />
        } />

        <Route exact path="/" element={
            <RequireAuth>
                <MapContainer />
            </RequireAuth>
        } />
        <Route exact path="/locations/:id" element={
            <RequireAuth>
                <LocationContainer />
            </RequireAuth>
        } />

        <Route exact path="/my-profile" element={
            <RequireAuth>
                <ProfileContainer />
            </RequireAuth>
        } />
        <Route exact path="/account-info" element={
            <RequireAuth role="ADMIN">
                <AccountInfoComponent />
            </RequireAuth>
        } />
        <Route exact path="/upload-data" element={
            <RequireAuth role="ADMIN">
                <UploadDataContainer />
            </RequireAuth>
        } />
        {/*<Route path="/report-panel" element={*/}
        {/*    <RequireAuth role="ADMIN">*/}
        {/*        <ReportPanelContainer />*/}
        {/*    </RequireAuth>*/}
        {/*} />*/}
        <Route exact path="/data-sharing" element={
            <RequireAuth role="ADMIN">
                <DataSharingContainer />
            </RequireAuth>
        } />
        <Route exact path="/dashboard" element={
            <RequireAuth>
                <PreplansContainer />
            </RequireAuth>
        } />
        <Route exact path="/export/:id" element={
            <RequireAuth>
                <PrePlanExport />
            </RequireAuth>
        } />

        {process.env.NODE_ENV === 'development' && (
            <Route exact path="/tests/annotator" element={
                <RequireAuth role="ADMIN">
                    <App />
                </RequireAuth>
            } />
        )}

    </Routes>
);
