import React from 'react';
import { Box, Stack, Text, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, GridItem, Grid, Divider, Flex, Spacer } from '@chakra-ui/react';
import { AddressStack } from './AddressStack';
import { OutlineButton } from '../form/Button';
import AddIcon from '../FigmaExport/AddIcon';
import CycleIcon from '../FigmaExport/CycleIcon';
import { HydrantsInfoPanel } from './HydrantsInfoPanel';


export const structureFields = [
  {
    label: 'Structure Name',
    name: 'name'
  },
  {
    label: 'Address',
    name: 'address'
  },
  {
    label: 'Occupancy Type',
    name: 'occupancyType'
  },
  {
    label: 'Construction Type',
    name: 'constructionType'
  },
  {
    label: 'Roof Type',
    name: 'roofType'
  },
  {
    label: 'Roof Construction',
    name: 'roofConstruction'
  },
  {
    label: 'Roof Material',
    name: 'roofMaterial'
  },
  {
    label: 'Sprinklered',
    name: 'sprinklered'
  },
  {
    label: 'Stand Pipe',
    name: 'standPipe'
  },
  {
    label: 'Fire Alarm',
    name: 'fireAlarm'
  },
  {
    label: 'Normal Population',
    name: 'normalPopulation'
  },
  {
    label: 'Hours of Operation',
    name: 'hoursOfOperation'
  },
  {
    label: 'Owner Contact',
    name: 'ownerContact'
  },
  {
    label: 'Owner Phone',
    name: 'ownerPhone'
  },
  {
    label: 'Original Pre-Plan',
    name: 'originalPrePlan'
  },
  {
    label: 'Last Reviewed On',
    name: 'lastReviewedOn'
  },
  {
    label: 'Last Reviewed By',
    name: 'lastReviewedBy'
  },
  {
    label: 'Notes',
    name: 'notes'
  },
  {
    label: 'Third-Party Notes',
    name: 'partnerNotes',
    hideIfEmpty: true
  }
];


const StructureInfoPanel = ({ structure, handleAddOccupant }) => {
  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: '1.75rem',
    color: 'gray.700'
  };

  return (structure && ( 
    <Box width="100%" padding="12px" borderWidth='1px' borderRadius='lg' overflow='hidden'>
      <Accordion defaultIndex={0} allowToggle>
        <AccordionItem border={0}>
          <h2>
            <AccordionButton paddingLeft={0} paddingY={0}>
              <Box as='h2' fontSize="lg" fontWeight="600" flex='1' textAlign='left'>
                {structure.name || "Structure"}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding={0}>
            <Divider marginY={1}/>
            <Flex paddingY="12px" >
              <OutlineButton
                fontSize="sm"
                color="#2C62CB"
                borderColor="#2C62CB"
                leftIcon={<AddIcon fill="#2C62CB" />}
                onClick={() => handleAddOccupant(structure, false)}
              >
                Add Occupant
              </OutlineButton>
              <Spacer />
              <OutlineButton
                fontSize="sm"
                color="#2C62CB"
                borderColor="#2C62CB"
                leftIcon={<CycleIcon />}
                onClick={() => handleAddOccupant(structure, true)}
              >
                Convert to Occupant
              </OutlineButton>
            </Flex>
            <Stack spacing="">
              {structureFields.map((field, index) => (!field.hideIfEmpty || structure[field.name]) && (
                <Grid key={index} templateColumns='repeat(2, 1fr)'>
                  <GridItem><Text {...labelStyle}>{field.label}</Text></GridItem>
                  <GridItem>{field.name === "address" ? <AddressStack {...structure.address} /> : <Text fontWeight="600" fontSize="sm">{structure[field.name]}</Text>}</GridItem>
                </Grid>
              ))}
            </Stack>
            <HydrantsInfoPanel structure={structure} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  ));
};


export default StructureInfoPanel;
