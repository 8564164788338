import React from 'react';
import {Route, Routes} from 'react-router-dom';
import LoginContainer from './containers/LoginContainer';
import PasswordResetContainer from './containers/PasswordResetContainer';
import SignUpSecondaryContainer from './containers/SignUpSecondaryContainer';
import CreateDepartmentContainer from './containers/CreateDepartmentContainer';
import AutoLoginContainer from './containers/AutoLoginContainer';
import OidcRootContainer from "./containers/OidcRootContainer";
import DepartmentLoginContainer from "./containers/DepartmentLoginContainer";
import OidcSignUpContainer from "./containers/OidcSignUpContainer"
import OidcCallbackContainer from "./containers/OidcCallbackContainer";
import OidcLinkUserContainer from "./containers/OidcLinkUserContainer";
import {Navigate} from "react-router-dom";


export default (
    <Routes>

        <Route exact path="/login" Component={LoginContainer} />
        <Route exact path="/login/:slug" Component={DepartmentLoginContainer} />
        <Route exact path="/loginAuto" Component={AutoLoginContainer} />
        <Route exact path="/passwordResetForm" Component={PasswordResetContainer} />
        <Route exact path="/signUpForm" Component={SignUpSecondaryContainer} />
        <Route exact path="/createDepartment" Component={CreateDepartmentContainer} />

        <Route exact path="/oidc-callback" Component={OidcCallbackContainer} />
        <Route exact path="/oidc" Component={OidcRootContainer} />
        <Route exact path="/oidc/:slug/login" element={<DepartmentLoginContainer requireOidc />} />
        <Route exact path="/oidc/:slug/signUpForm" Component={OidcSignUpContainer} />
        <Route exact path="/oidc/:slug/linkUserForm" Component={OidcLinkUserContainer} />

        <Route exact path="/" element={
            <Navigate to="/login" replace />
        } />

        <Route exact path="/my-profile" element={
            <Navigate to="/login" replace />
        } />

        <Route exact path="/account-info" element={
            <Navigate to="/login" replace />
        } />

        <Route exact path="/upload-data" element={
            <Navigate to="/login" replace />
        } />

        <Route exact path="/data-sharing" element={
            <Navigate to="/login" replace />
        } />

        <Route exact path="/dashboard" element={
            <Navigate to="/login" replace />
        } />

        <Route exact path="/export/:id" element={
            <Navigate to="/login" replace />
        } />

    </Routes>
);
