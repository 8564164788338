import React from 'react';
import { Box, Stack, Text, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, GridItem, Grid, Divider } from '@chakra-ui/react';
import { AddressStack } from './AddressStack';


const PropertyInfoPanel = ({ property, handleConnectPropertyStructures }) => {
  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: '1.75rem',
    color: 'gray.700'
  };

  const onLinkToPropety = (e) => {
    handleConnectPropertyStructures(property.id);
    e.preventDefault(); // if we don't preventDefault here, it may submit PrePlan form again.
  };

  return (property && (
    <Box width="100%" padding="12px" borderWidth='1px' borderRadius='lg' overflow='hidden'>
      <Accordion defaultIndex={0} allowToggle>
        <AccordionItem border={0}>
          <h2>
            <AccordionButton paddingLeft={0} paddingY={0}>
              <Box as='h2' fontSize="lg" fontWeight="600" flex='1' textAlign='left'>
                Property Info
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding={0}>
            <Divider marginY={1}/>
            <Stack spacing="">
              <Grid templateColumns='repeat(2, 1fr)'>
                <GridItem><Text {...labelStyle}>Link to Property</Text></GridItem>
                <GridItem><Text as="button" fontWeight="600" fontSize="sm" textAlign="left" color="#2C62CB" onClick={onLinkToPropety} >Connect Property Structures</Text></GridItem>
              </Grid>
              <Grid templateColumns='repeat(2, 1fr)'>
                <GridItem><Text {...labelStyle}>Property Name:</Text></GridItem>
                <GridItem><Text fontWeight="600" fontSize="sm">{property.name}</Text></GridItem>
              </Grid>
              <Grid templateColumns='repeat(2, 1fr)'>
                <GridItem><Text {...labelStyle}>Property Address:</Text></GridItem>
                <GridItem><AddressStack {...property.address} /></GridItem>
              </Grid>
              <Grid templateColumns='repeat(2, 1fr)'>
                <GridItem><Text {...labelStyle}># of Structures:</Text></GridItem>
                <GridItem><Text fontWeight="600" fontSize="sm">{property.structures && property.structures.length}</Text></GridItem>
              </Grid>
              <Grid templateColumns='repeat(2, 1fr)'>
                <GridItem><Text {...labelStyle}>District:</Text></GridItem>
                <GridItem><Text fontWeight="600" fontSize="sm">{property.customerZone}</Text></GridItem>
              </Grid>
              <Grid templateColumns='repeat(2, 1fr)'>
                <GridItem><Text {...labelStyle}>Station:</Text></GridItem>
                <GridItem><Text fontWeight="600" fontSize="sm">{property.station}</Text></GridItem>
              </Grid>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  ));
};


export default PropertyInfoPanel;
