import React from 'react';

import FormComponent from './FormComponent';


export const formFields = [
  {
    label: 'Structure Name',
    id: 'structureName',
    name: 'structureName',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Commercial',
    id: 'commercialStructure',
    name: 'commercialStructure',
    type: 'checkbox',
    description: 'Define as Commercial Location for ISO'
  },
  {
    label: 'Occupancy Type',
    id: 'occupancyType',
    name: 'occupancyType',
    type: 'select',
    options: [
      {
        label: 'Select Occupancy Type',
        value: ''
      },
      {
        label: 'Assembly',
        value: 'Assembly'
      },
      {
        label: 'Board & Care',
        value: 'Board & Care'
      },
      {
        label: 'Business / Mercantile',
        value: 'Business / Mercantile'
      },
      {
        label: 'Day-Care',
        value: 'Day-Care'
      },
      {
        label: 'Detention & Correctional',
        value: 'Detention & Correctional'
      },
      {
        label: 'Educational',
        value: 'Educational'
      },
      {
        label: 'High Hazard',
        value: 'High Hazard'
      },
      {
        label: 'Industrial',
        value: 'Industrial'
      },
      {
        label: 'Medical Care / Institutional',
        value: 'Medical Care / Institutional'
      },
      {
        label: 'Mixed Business / Residential',
        value: 'Mixed Business / Residential'
      },
      {
        label: 'Multi-Family',
        value: 'Multi-Family'
      },
      {
        label: 'Residential',
        value: 'Residential'
      },
      {
        label: 'Special Structures',
        value: 'Special Structures'
      },
      {
        label: 'Storage',
        value: 'Storage'
      }
    ],
    required: false
  },
  {
    label: 'Construction Type',
    id: 'constructionType',
    name: 'constructionType',
    type: 'select',
    options: [
      {
        label: 'Select Construction Type',
        value: ''
      },
      {
        label: 'Not Classified',
        value: 'Not Classified'
      },
      {
        label: 'Type IA - Fire Resistive, Non-combustible',
        value: 'Type IA - Fire Resistive, Non-combustible'
      },
      {
        label: 'Type IB - Fire Resistive, Non-combustible',
        value: 'Type IB - Fire Resistive, Non-combustible'
      },
      {
        label: 'Type IIA - Protective, Non-combustible',
        value: 'Type IIA - Protective, Non-combustible'
      },
      {
        label: 'Type IIB - Unprotective, Non-combustible',
        value: 'Type IIB - Unprotective, Non-combustible'
      },
      {
        label: 'Type IIIA - Protected Ordinary',
        value: 'Type IIIA - Protected Ordinary'
      },
      {
        label: 'Type IIIB - Unprotected Ordinary',
        value: 'Type IIIB - Unprotected Ordinary'
      },
      {
        label: 'Type IV - Heavy Timber',
        value: 'Type IV - Heavy Timber'
      },
      {
        label: 'Type VA - Protected Combustible',
        value: 'Type VA - Protected Combustible'
      },
      {
        label: 'Type VB - Unprotected Combustible',
        value: 'Type VB - Unprotected Combustible'
      }
    ],
    required: false
  },
  {
    label: 'Roof Type',
    id: 'roofType',
    name: 'roofType',
    type: 'select',
    options: [
      {
        label: 'Select Roof Type',
        value: ''
      },
      {
        label: 'Bonnet',
        value: 'Bonnet'
      },
      {
        label: 'Bowstring Truss',
        value: 'Bowstring Truss'
      },
      {
        label: 'Butterfly',
        value: 'Butterfly'
      },
      {
        label: 'Combination',
        value: 'Combination'
      },
      {
        label: 'Curved',
        value: 'Curved'
      },
      {
        label: 'Dome',
        value: 'Dome'
      },
      {
        label: 'Flat',
        value: 'Flat'
      },
      {
        label: 'Gable',
        value: 'Gable'
      },
      {
        label: 'Gambrel',
        value: 'Gambrel'
      },
      {
        label: 'Hip',
        value: 'Hip'
      },
      {
        label: 'Jerkinhead',
        value: 'Jerkinhead'
      },
      {
        label: 'Mansard',
        value: 'Mansard'
      },
      {
        label: 'Pyramid',
        value: 'Pyramid'
      },
      {
        label: 'Saltbox',
        value: 'Saltbox'
      },
      {
        label: 'Sawtooth',
        value: 'Sawtooth'
      },
      {
        label: 'Skillion',
        value: 'Skillion'
      }
    ],
    required: false
  },
  {
    label: 'Roof Construction',
    id: 'roofConstruction',
    name: 'roofConstruction',
    type: 'select',
    options: [
      {
        label: 'Select Roof Construction',
        value: ''
      },
      {
        label: 'Beam - Concrete',
        value: 'Beam - Concrete'
      },
      {
        label: 'Beam - Steel',
        value: 'Beam - Steel'
      },
      {
        label: 'Beam - Wood',
        value: 'Beam - Wood'
      },
      {
        label: 'Steel Truss - Open Web',
        value: 'Steel Truss - Open Web'
      },
      {
        label: 'Wood / Steel - Closed Web',
        value: 'Wood / Steel - Closed Web'
      },
      {
        label: 'Wood / Steel - Open Web',
        value: 'Wood / Steel - Open Web'
      },
      {
        label: 'Wood Truss - Closed Web',
        value: 'Wood Truss - Closed Web'
      },
      {
        label: 'Wood Truss - Open Web',
        value: 'Wood Truss - Open Web'
      }
    ],
    required: false
  },
  {
    label: 'Roof Material',
    id: 'roofMaterial',
    name: 'roofMaterial',
    type: 'select',
    options: [
      {
        label: 'Select Roof Material',
        value: ''
      },
      {
        label: 'Built-Up',
        value: 'Built-Up'
      },
      {
        label: 'Composition Shingles',
        value: 'Composition Shingles'
      },
      {
        label: 'Membrane - Plastic elastomer',
        value: 'Membrane - Plastic elastomer'
      },
      {
        label: 'Membrane - Synthetic elastomer',
        value: 'Membrane - Synthetic elastomer'
      },
      {
        label: 'Metal',
        value: 'Metal'
      },
      {
        label: 'Metal - Corrugated',
        value: 'Metal - Corrugated'
      },
      {
        label: 'Metal - Shake',
        value: 'Metal - Shake'
      },
      {
        label: 'Metal - Standing Seam',
        value: 'Metal - Standing Seam'
      },
      {
        label: 'Roof Covering Not Class',
        value: 'Roof Covering Not Class'
      },
      {
        label: 'Roof Covering Undetermined/Not Reported',
        value: 'Roof Covering Undetermined/Not Reported'
      },
      {
        label: 'Shingle - Asphalt / Composition',
        value: 'Shingle - Asphalt / Composition'
      },
      {
        label: 'Slate - Composition',
        value: 'Slate - Composition'
      },
      {
        label: 'Slate - Natural',
        value: 'Slate - Natural'
      },
      {
        label: 'Structure Without Roof',
        value: 'Structure Without Roof'
      },
      {
        label: 'Tile - Clay',
        value: 'Tile - Clay'
      },
      {
        label: 'Tile - Concrete',
        value: 'Tile - Concrete'
      },
      {
        label: 'Tile (clay, cement, slate, etc.)',
        value: 'Tile (clay, cement, slate, etc.)'
      },
      {
        label: 'Wood - Shingle/Shake',
        value: 'Wood - Shingle/Shake'
      },
      {
        label: 'Wood Shakes/Shingles (Treated)',
        value: 'Wood Shakes/Shingles (Treated)'
      },
      {
        label: 'Wood Shakes/Shingles (Untreated)',
        value: 'Wood Shakes/Shingles (Untreated)'
      }
    ],
    required: false
  },
  {
    label: 'Sprinklered',
    id: 'sprinklered',
    name: 'sprinklered',
    type: 'select',
    options: [
      {
        label: 'Select Sprinkler Type',
        value: ''
      },
      {
        label: 'Dry System',
        value: 'Dry System'
      },
      {
        label: 'Wet System',
        value: 'Wet System'
      },
      {
        label: 'Both',
        value: 'Both'
      },
      {
        label: 'None',
        value: 'None'
      }
    ],
    required: false
  },
  {
    label: 'Stand Pipe',
    id: 'standPipe',
    name: 'standPipe',
    type: 'select',
    options: [
      {
        label: 'Select Stand Pipe',
        value: ''
      },
      {
        label: 'Yes',
        value: 'Yes'
      },
      {
        label: 'No',
        value: 'No'
      }
    ],
    required: false
  },
  {
    label: 'Fire Alarm',
    id: 'fireAlarm',
    name: 'fireAlarm',
    type: 'select',
    options: [
      {
        label: 'Select Fire Alarm',
        value: ''
      },
      {
        label: 'Yes',
        value: 'Yes'
      },
      {
        label: 'No',
        value: 'No'
      }
    ],
    required: false
  },
  {
    label: 'Normal Population',
    id: 'normalPopulation',
    name: 'normalPopulation',
    type: 'select',
    options: [
      {
        label: 'Select Normal Population',
        value: ''
      },
      {
        label: 'Vacant',
        value: 'Vacant'
      },
      {
        label: '1 - 10',
        value: '1 - 10'
      },
      {
        label: '11 - 50',
        value: '11 - 50'
      },
      {
        label: '51 - 100',
        value: '51 - 100'
      },
      {
        label: '101 - 500',
        value: '101 - 500'
      },
      {
        label: '501 - 1000',
        value: '501 - 1000'
      }
    ],
    required: false
  },
  {
    label: 'Hours Of Operation',
    id: 'hoursOfOperation',
    name: 'hoursOfOperation',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Owner Contact',
    id: 'ownerContact',
    name: 'ownerContact',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Owner Phone',
    id: 'ownerPhone',
    name: 'ownerPhone',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Notes (Optional)',
    id: 'notes',
    name: 'notes',
    type: 'textarea',
    placeholder: ''
  },
];

export const readOnlyFields = [
  {
    label: 'Third-Party Notes',
    id: 'partnerNotes'
  }
];

export const allFields = [...formFields, ...readOnlyFields];

export const StructureForm = (props) => {
  return <FormComponent data={props.structureDataForm} formFields={formFields} />;
};
export default StructureForm;
