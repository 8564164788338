import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Icon,
  Stack,
  Tooltip,
  Text,
  IconButton,
  Flex
} from '@chakra-ui/react';
import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt';

import { setSelectedStructureId, selectStructureById } from '../../features/structures/structuresSlice';
import { setSelectedHydrants } from '../../features/hydrants/hydrantsSlice';
import { formatLocationDate, formatDate, formatDateFromISO } from '../../helpers/Date';

import { AssignmentBadge } from '../../components/assignments/AssignmentBadge';
import { CompletionPopover } from './CompletionPopover';
import {useNavigate} from "react-router-dom";
import ArrowUpIcon from '../../components/FigmaExport/ArrowUpIcon';
import ArrowDownIcon from '../../components/FigmaExport/ArrowDownIcon';


export const ROW_TYPES = {
  STRUCTURE: "STRUCTURE",
  OCCUPANT: "OCCUPANT",
};

const locationColumns = {
  Header: 'Location',
  width: 30,
  columns: [{
    id: 'address',
    Header: 'Address',
    minWidth: 150,
    maxWidth: 250,
    accessor: (row) => {
      // compiles address vals into string for searchable entity
      return [...(row.name ? [row.name] : []), ...Object.values(row.address ? row.address : {})].join(', ');
    },
    Cell: ({ /*value,*/ row }) => {
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const structure = useSelector(state => selectStructureById(state, row.original.id));
      const onClick = () => {
        if (row.original.type !== ROW_TYPES.STRUCTURE) return;
        navigate('/');
        // @todo Not like this: this is a hacky way to make sure map re-centers on location
        setTimeout(() => {
          dispatch(setSelectedStructureId(row.original.id));
          dispatch(setSelectedHydrants(structure.hydrants ? structure.hydrants : []));
        }, 100);
      };
      const address = row.original.address;
      return row.original.type === ROW_TYPES.STRUCTURE ? (
        <Flex pl={`${row.depth * 2}rem`}>
          <Tooltip label={<Text>View on map <Icon as={FaExternalLinkAlt} height="0.75rem" width="0.75rem" /></Text>} placement="left">
            <Stack className="address" mr="auto" minWidth="8rem" spacing="0px" cursor="pointer" onClick={onClick} _hover={{ textDecoration: 'underline' }}>
              {(!address || Object.keys(address).length < 1) && (
                <Text>No Address Data</Text>
              )}
              {row.original.name && <span className="name">{row.original.name}</span>}
              {address && address.address1 && <span className="address1">{address.address1}</span>}
              {address && address.address2 && <span className="address2">{address.address2}</span>}
              {address && address.city && <span className="city-zip"><span>{address.city}, {address.state} {address.zip}</span></span>}
            </Stack>
          </Tooltip>
          {row.canExpand ? (
            <IconButton
              icon={row.isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
              {...row.getToggleRowExpandedProps()}
            />
          ) : null}
        </Flex>
      ) : (
        <Text pl={`${row.depth * 2}rem`} fontSize="md" fontWeight={500}>{row.original.name}</Text>
      );
    }
  },
  {
    id: 'type',
    Header: 'Type',
    accessor: 'type',
    width: 50,
  },
  {
    id: 'lotNumber',
    Header: 'Lot #',
    accessor: 'lotNumber',
    width: 60,
  },
  {
    id: 'customerZone',
    Header: 'District',
    accessor: 'customerZone',
    width: 60,
    className: 'district'
  },
  {
    id: 'storey',
    Header: 'Floors Above',
    accessor: 'storey',
    width: 60,
  },
  {
    id: 'storeyBelow',
    Header: 'Floors Below',
    accessor: 'storeyBelow',
    width: 60,
  },
  {
    id: 'roofArea',
    Header: 'Roof Area',
    accessor: 'roofArea',
    width: 60,
  },
  ]
};

const preplanColumns = {
  Header: 'Preplan',
  width: 40,
  className: 'cell-center',
  columns: [
    {
      id: 'hydrants',
      Header: 'Hydrants',
      accessor: 'hydrants',
      className: 'hydrants',
      width: 80
    },
    {
      id: 'images',
      Header: 'Images',
      accessor: 'imageLength',
      className: 'images',
      width: 70
    },
    {
      id: 'imageAnnotations',
      Header: 'Image Annotations',
      accessor: 'imageAnnotations',
      width: 85,
      Cell: ({ value, row }) => (<Text fontSize="sm" fontWeight={600}>{value}/{row.original.imageLength}</Text>)
    },
    {
      Header: 'Completion',
      id: 'completion',
      width: 100,
      accessor: (row) => row.completion.completion,
      className: 'completion',
      filter: 'between',
      Cell: ({ value, row }) => <CompletionPopover value={value} row={row} />
    },
    {
      Header: 'Floor Plan',
      id: 'floorPlan',
      className: 'floorPlan',
      width: 70,
      accessor: (row) => row.floorPlan ? 1 : 0,
      Cell: ({ value }) => value ? <Text fontSize="sm" fontWeight={600}>Yes</Text> : ''
    },
    {
      Header: 'High Hazard',
      className: 'highHazard',
      id: 'isHighHazard',
      width: 70,
      accessor: (row) => row.isHighHazard ? 1 : 0,
      Cell: ({ value }) => value ? <Text fontSize="sm" fontWeight={600}>Yes</Text> : ''
    },
    {
      Header: 'Risk Assessment Score',
      className: 'riskAssessmentScore',
      id: 'riskAssessmentScore',
      accessor: (row) => row.riskAssessmentScore,
      width: 70,
      Cell: ({ value }) => {
        return <Text fontSize="sm" fontWeight={600}>{value}</Text>;
      }
    },
    {
      id: 'reviewStatus',
      Header: 'Review Status',
      accessor: 'reviewStatus',
      width: 70,
      Cell: ({ value }) => <Text fontSize="sm" fontWeight={600}>{value}</Text>
    },
    {
      id: 'lastReviewedBy',
      Header: 'Last Reviewed By',
      accessor: 'lastReviewedBy',
    },
    {
      Header: 'Last Reviewed',
      id: 'lastReviewedOn',
      filter: 'between',
      accessor: (row) => {
        return formatLocationDate(row.lastReviewedOn).getTime();
      },
      Cell: ({ value, row }) => {
        return (<Stack className="name-date" spacing={'0'}>
          <div className="name">{row.original.lastReviewedBy}</div>
          {value && (
            <div className="date">{
              formatDate(value)
            }</div>
          )}
        </Stack>);
      }
    },
    {
      Header: 'Created',
      id: 'originalPrePlan',
      filter: 'between',
      accessor: (row) => {
        return formatLocationDate(row.originalPrePlan).getTime();
      },
      Cell: ({ value, /*row*/ }) => value ? (<Stack className="name-date">
        <div className="date">{formatDate(value)}</div>
      </Stack>) : null
    }
  ]
};

const assignmentColumns = {
  Header: 'Assignment',
  width: 30,
  columns: [
    {
      Header: 'Assigned To',
      id: 'assignedTo',
      accessor: 'assignedTo',
      filter: 'equals',
      Cell: ({ value }) => <Box maxWidth="6rem"><Text fontSize="sm">{value}</Text></Box>
    },
    {
      Header: 'Due',
      id: 'assignmentDueOn',
      accessor: 'assignmentDueOn',
      Cell: ({ value }) => value ? (
        (<Stack className="name-date">
          <div className="date">{formatDateFromISO(value)}</div>
        </Stack>)
      ) : null
    },
    {
      Header: 'Status',
      minWidth: 150,
      maxWidth: 250,
      id: 'assignmentStatus',
      accessor: 'assignmentStatus',
      className: 'assignmentStatus',
      Cell: ({ /*row,*/ value }) => (value ? <Box minWidth="8rem"><AssignmentBadge status={value} /></Box> : null)
    },
    {
      Header: 'Assignment Notes',
      id: 'assignmentNotes',
      accessor: 'assignmentNotes',
    }
  ]
};

const buildingColumns = {
  id: 'building',
  Header: 'Building',
  columns: [
    {
      id: 'building.commercialStructure',
      width: 70,
      Header: 'Commercial',
      accessor: (row) => (row.commercialStructure) ? 1 : 0,
      Cell: ({ value }) => value ? <Text fontSize="sm" fontWeight={600}>Yes</Text> : '',
    },
    {
      id: 'building.occupancyType',
      width: 170,
      Header: 'Occupancy Type',
      accessor: 'building.occupancyType'
    },
    {
      id: 'building.constructionType',
      width: 170,
      Header: 'Construction Type',
      accessor: 'building.constructionType'
    },
    {
      id: 'building.roofType',
      width: 170,
      Header: 'Roof Type',
      accessor: 'building.roofType'
    },
    {
      id: 'building.roofConstruction',
      width: 170,
      Header: 'Roof Construction',
      accessor: 'building.roofConstruction'
    },
    {
      id: 'building.roofMaterial',
      width: 170,
      Header: 'Roof Material',
      accessor: 'building.roofMaterial'
    },
    {
      id: 'building.sprinklered',
      width: 170,
      Header: 'Sprinklered',
      accessor: 'building.sprinklered'
    },
    {
      id: 'building.standPipe',
      width: 170,
      Header: 'Stand Pipe',
      accessor: 'building.standPipe'
    },
    {
      id: 'building.fireAlarm',
      width: 170,
      Header: 'Fire Alarm',
      accessor: 'building.fireAlarm'
    },
    {
      id: 'building.normalPopulation',
      width: 170,
      Header: 'Normal Population',
      accessor: 'building.normalPopulation'
    },
    {
      id: 'building.hoursOfOperation',
      width: 170,
      Header: 'Hours Of Operation',
      accessor: 'building.hoursOfOperation'
    },
    {
      id: 'building.ownerContact',
      width: 170,
      Header: 'Owner Contact',
      accessor: 'building.ownerContact'
    },
    {
      id: 'building.ownerPhone',
      width: 170,
      Header: 'Owner Phone',
      accessor: 'building.ownerPhone'
    },
    {
      id: 'building.notes',
      width: 170,
      Header: 'Notes (Optional)',
      accessor: 'building.notes'
    },
    {
      id: 'building.partnerNotes',
      width: 170,
      Header: 'Third-Party Notes',
      accessor: 'building.partnerNotes'
    }
  ]
};

export const allColumns = [
  locationColumns,
  preplanColumns,
  assignmentColumns,
  buildingColumns
];

export const allColumnIds = [
  ...allColumns.flatMap(c => c.columns).map(c => c.id || (typeof(c.accessor) === 'string' ? c.accessor : undefined) || c.Header)
];

export const hiddenColumnIds = [
  'id',
  'lastReviewedBy',
  'imageLength',
  'originalPrePlan',
  'riskAssessmentScore',
  'storey',
  'storeyBelow',
  'roofArea',
  'building.commercialStructure',
  'building.occupancyType',
  'building.constructionType',
  'building.roofType',
  'building.roofConstruction',
  'building.roofMaterial',
  'building.sprinklered',
  'building.standPipe',
  'building.fireAlarm',
  'building.normalPopulation',
  'building.hoursOfOperation',
  'building.ownerContact',
  'building.ownerPhone',
  'building.notes',
  'building.partnerNotes',
];
