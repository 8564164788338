/* global google */

import React, { useEffect, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, Polygon } from '@react-google-maps/api';
import * as util from '../../helpers/Util';
import DrawingTools from './DrawingToolsComponent';
import HydrantMarkerInfo from './HydrantMarkerInfo';
import { useDebounce } from '../../hooks/useDebounce';
import { midPoint } from '../../helpers/GoogleMaps';
import { shouldNotUpdate } from '../../helpers/Memo';
import { useDispatch } from 'react-redux';
import { setHydrantIdToLocate } from '../../features/hydrants/hydrantsSlice';


// Custom Pins
// Pick your pin (hole or no hole)
const pinSVGFilled = 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z';
const labelOriginFilled = { x: 12, y: 9 };
const polygonFillColor = '#4992A9';
const polygonSelectedFillColor = '#FF4214';
const polygonSharedFillColor = '#808000';
const polygonSharedSelectedFillColor = '#FF0000';
const polygonStrokeColor = '#4992A9';


const getMarkerProps = (props, marker, kounter, isMoving) => {
  const isSelected = marker.isSelected || isMoving;
  return {
    position: marker.position,
    onClick: () =>
      props.onMarkerEvent(marker, { isSelected: !marker.isSelected }),
    onRightClick: e => props.onMarkerRightClick(e, marker),
    options: {
      icon: {
        url: util.getMarkerIcon(marker.isMine, marker.dryHydrant, marker.inService, marker.pinColor, isSelected, props.zoom),
        anchor: {
          x: isSelected ? 25 : 7,
          y: isSelected ? 29 : 13
        },
        optimized: true
      }
    }
  };
};

const getLocationProps = (location, isMoving, buildingInMove) => {
  const locationToRender = isMoving ? buildingInMove.location : location;
  return {
    options: {
      draggable: isMoving,
      paths: locationToRender.geoOutline.map(coor => ({ lat: coor.latitude, lng: coor.longitude })),
      strokeColor: polygonStrokeColor,
      strokeOpacity: 1,
      strokeWeight: 3,
      fillColor: location.isMine ? (location.isSelected ? polygonSelectedFillColor : polygonFillColor) : location.isSelected ? polygonSharedSelectedFillColor : polygonSharedFillColor,
      fillOpacity: 0.5
    },
  };
};


export const CustomMap = ({
  distancePolyline,
  visibleLocations,
  visibleHydrants,
  zoom,
  markersFromSearch,
  bounds,
  center,
  mapTypeId,
  disableDblClick,
  onMarkerClose,
  onStructureClick,
  onStructureRightClick,
  handleMapMounted,
  onZoomChanged,
  onMapClick,
  onMapRightClick,
  onMapTypeIdChanged,
  onIdle,
  selectedStructures,
  selectedStructureId,
  ...props
}) => {
  const doClustering = zoom <= util.ZOOM_LEVELS.clusterThreshold;
  const [markersVis, setMarkersVis] = useState([]);

  const googleMapProps = {
    tilt: 0,
    center,
    zoom,
    onLoad: handleMapMounted,
    onZoomChanged: onZoomChanged,
    onClick: onMapClick,
    onRightClick: onMapRightClick,
    onMapTypeIdChanged: onMapTypeIdChanged,
    onIdle: onIdle,
  };

  useEffect(() => {

    const onDoubleClickItem = (location) => (e) => {
      if (disableDblClick) {
        onStructureRightClick(location)(e);
      }
    };

    const markers = [];
    if (bounds) {

      if (doClustering) {
        // @todo better clustering
        // markers.push(
        //   <MarkerClusterer
        //     // options={{
        //     //   title: 'Hydrants'
        //     // }}
        //     title={'Hydrants'}
        //     maxZoom={util.ZOOM_LEVELS.clusterThreshold}
        //     minimumClusterSize={2}
        //     zoomOnClick={false}
        //   >
        //     {(clusterer) => visibleHydrants.map((marker, index) => (
        //       <HydrantMarker marker={marker} key={`${marker.id}-hydrantmarker`} clusterer={clusterer} {...getMarkerProps(props, marker, index)} />
        //     ))}
        //   </MarkerClusterer>
        // );
      } else {
        markers.push(visibleHydrants
          .map((hydrant, index) => (
            <HydrantMarker
              hydrantIdToLocate={props.hydrantIdToLocate}
              hydrantInMove={props.hydrantInMove}
              onHydrantDragEnd={props.onHydrantDragEnd}
              lock={props.hydrantInMove || props.buildingInMove}
              hydrant={hydrant}
              key={`${hydrant.id}-hydrantmarker${props.hydrantInMove && props.hydrantInMove.id === hydrant.id ? 'm' : 's'}`} // this differentiation in the key forces re-render after canceling move
              onDblClick={e => {
                if (disableDblClick) {
                  props.onMarkerRightClick(e, hydrant);
                }
              }}
              {...getMarkerProps(props, hydrant, index, props.hydrantInMove && props.hydrantInMove.id === hydrant.id)} />
          )));
      }
    }

    markers.push(markersFromSearch.map((marker, index) => (
      <Marker key={`${index}-searchMarker`} position={marker.position} onClick={() => props.setMarkersFromSearch([])} />
    )));


    if (bounds && zoom > 14) {
      const locs = visibleLocations;

      markers.push(locs.map((structure, index) => (
        structure.latLon && (structure.latLon.latitude && structure.latLon.longitude) ? (
          <Marker
            draggable={props.buildingInMove && props.buildingInMove.structure && props.buildingInMove.structure.id === structure.id}
            onDragEnd={e => props.onBuildingPinDragEnd({ latitude: e.latLng.lat(), longitude: e.latLng.lng() })}
            icon={{
              path: pinSVGFilled,
              anchor: { x: 12, y: 17 },
              fillOpacity: 1,
              fillColor: structure.id === selectedStructureId ? polygonFillColor : polygonSelectedFillColor,
              strokeWeight: 2,
              strokeColor: 'white',
              scale: 2,
              labelOrigin: labelOriginFilled
            }}
            key={`${structure.id}-locationmarker`}
            position={{
              lat: structure.latLon.latitude,
              lng: structure.latLon.longitude
            }}
            onClick={() => onStructureClick(structure)}
            onRightClick={onStructureRightClick(structure)}
            onDblClick={onDoubleClickItem(structure)}
          />
        ) : (
          <StructurePolygon
            key={`${structure.id}-poly`}
            isSelected={(!selectedStructures && structure.id === selectedStructureId)
              || (selectedStructures && selectedStructures.find(s => s.id === structure.id))}
            structure={structure}
            onClick={() => onStructureClick(structure)}
            onRightClick={onStructureRightClick(structure)}
            onDblClick={onDoubleClickItem(structure)}
            lock={props.hydrantInMove || props.buildingInMove}
            buildingInMove={props.buildingInMove}
            onBuildingDragEnd={props.onBuildingDragEnd}
            isMoving={props.buildingInMove && props.buildingInMove.structure && props.buildingInMove.structure.id === structure.id}
            setBuildingDragStartingPoint={props.setBuildingDragStartingPoint}
          />
        ))));

      if (zoom >= 18) {
        const selectedStructure = locs.find(structure => structure.id === selectedStructureId)
        const propertyId = selectedStructure && selectedStructure.propertyId;
        const structuresToMark = locs.filter(structure => structure.id === selectedStructureId || (propertyId && structure.propertyId && structure.propertyId === propertyId));

        if (structuresToMark.length > 0) {
          markers.push(structuresToMark
            .map((structure, index) =>
            (<Marker
              key={`${structure.id}-lot`}
              position={midPoint(structure.geoOutline.map(coor => ({ lat: coor.latitude, lng: coor.longitude })))}
              icon={{
                path: google.maps.SymbolPath.CIRCLE,
              }}
              visible
              options={{
                shape: 'rect',
                label: {
                  text: structure.name || 'Structure',
                  className: 'structure-name-label',
                  fontSize: '12px',
                  fontWeight: '700',
                  fontFamily: 'Inter'
                }
              }}
            />)));
        }
      }
    }

    if (distancePolyline) {
      if (distancePolyline.polyline && distancePolyline.distance) {
        const lastPath = distancePolyline.polyline.getPath().getAt(distancePolyline.polyline.getPath().getLength() - 1);
        const DistanceMarker = (<Marker
          position={lastPath}
          icon={{
            path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
            strokeOpacity: 1,
            strokeWeight: 4,
            strokeColor: 'white',
            scale: 6,
            labelOrigin: new google.maps.Point(0, 6),
          }}
          visible
          draggable
          options={{
            label: {
              text: `${distancePolyline.distance}`,
              fontSize: '1rem',
              fontWeight: 'bold',
              className: 'structure-name-label',
            },
          }}
        />);
        markers.push(DistanceMarker);
      }
    }
    setMarkersVis(markers);
  }, [visibleHydrants, visibleLocations, markersFromSearch, distancePolyline, props.hydrantInMove]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [visibleHydrants, visibleLocations, markersFromSearch, distancePolyline, props.hydrantInMove, bounds, disableDblClick, doClustering, onStructureClick, onStructureRightClick, props, selectedStructureId, zoom]);
  // KMF: "fixing" this will cause perpetual re-renders until memory runs out... not good.

  const onDoubleClick = (e) => {
    if (disableDblClick) {
      onMapRightClick(e);
    }
  };

  // const drawingToolProps = {
  //   setRemovePolygons: props.setRemovePolygons,
  //   show: props.showDrawingTools,
  //   showOption: props.showDrawingToolsOption,
  //   prevPolylineData: props.prevPolylineData,
  //   onLoad: instance => {
  //     props._this.drawingTools = instance;
  //   },
  //   onPolygonComplete: props.onPolygonComplete,
  //   onPolylineComplete: props.onPolylineComplete
  // };

  return (
    <GoogleMap
      {...googleMapProps}
      mapContainerStyle={{
        width: '100%',
        height: '100%',
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
      }}
      onDblClick={onDoubleClick}
      options={{
        draggableCursor: props.newHydrant ? "crosshair" : "",
        mapTypeId: mapTypeId,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
              { visibility: 'off' }
            ]
          }
        ],
        disableDoubleClickZoom: (disableDblClick !== undefined) ? disableDblClick : false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        rotateControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
        streetViewControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER
        }
      }}
    >
      {markersVis}
      <DrawingTools
        setRemovePolygons={props.setRemovePolygons}
        show={props.showDrawingTools}
        showOption={props.showDrawingToolsOption}
        prevPolylineData={props.prevPolylineData}
        onLoad={ instance => {
          props._this.drawingTools = instance;
        }}
        onPolygonComplete={props.onPolygonComplete}
        onPolylineComplete={props.onPolylineComplete}
      />
    </GoogleMap>
  );
};

const StructurePolygon = (props) => {
  const [isSelected, setIsSelected] = useState(false);

  // An attempt to highlight polygon as selected before we wait for locations state to update
  // in an effort to speed up UI responsiveness
  useEffect(() => {
    setIsSelected(props.isSelected);
  }, [props.isSelected]);

  const onClick = () => {
    if (props.lock) return;
    setIsSelected(!isSelected);
    props.onClick();
  };

  const onDragStart = e => {
    props.setBuildingDragStartingPoint({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };
  
  const onDragEnd = e => {
    props.onBuildingDragEnd({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  return (
    <Polygon
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      {...props}
      {...getLocationProps({ ...props.structure, isSelected: isSelected }, props.isMoving, props.buildingInMove)}
      onClick={onClick}
    />
  );
};


const HydrantMarker = ({ hydrant, index, hydrantIdToLocate, ...props }) => {
  const dispatch = useDispatch();
  const [showInfo, setShowInfo] = useState(false);
  // debounce so that info window doesn't open when user is panning
  const debouncedShowInfo = useDebounce(showInfo, 125);
  const locateMe = hydrantIdToLocate && hydrantIdToLocate === hydrant.id;


  useEffect(() => {
    setShowInfo(locateMe);
  }, [locateMe]);

  const onClick = () => {
    hydrant.isSelected && setShowInfo(false);
    props.onClick();
  };

  const onMouseOut = () => {
    if (locateMe) return;
    setShowInfo(false);
  };

  const onMouseOver = () => {
    if (locateMe) return;
    if (props.lock) return;
    setShowInfo(true);
  };

  const markerProps = {
    ...props,
    onClick,
    onMouseOut,
    onMouseOver
  };

  return (
    <Marker 
      draggable={props.hydrantInMove && props.hydrantInMove.id === hydrant.id}
      onDragEnd={e => props.onHydrantDragEnd(e.pixel, { lat: e.latLng.lat(), lng: e.latLng.lng() })}
      key={`${hydrant.id}-hydrant`}
      {...markerProps}
    >
      {debouncedShowInfo && (
        <InfoWindow
          options={{ disableAutoPan: !locateMe, maxWidth: 250 }}
          onCloseClick={() => dispatch(setHydrantIdToLocate(null))}
        >
          <HydrantMarkerInfo hydrant={hydrant} />
        </InfoWindow>
      )}
    </Marker>
  );
};

export default React.memo(CustomMap, shouldNotUpdate);
